const generateToken = length => {
  //edit the token allowed characters
  let a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
  let b = [];
  for (let i = 0; i < length; i++) {
    let j = (Math.random() * (a.length - 1)).toFixed(0);
    b[i] = a[j];
  }
  return b.join("");
};

const queryString = params => {
  let str = '';

  Object.keys(params).forEach((key, idx) => {
    str += key + '=' + params[key];
    if (idx < Object.keys(params).length - 1) str += '&';
  });

  return str;
};

const trimString = (s, c) => {
  if (c === "]") c = "\\]";
  if (c === "\\") c = "\\\\";
  return s.replace(new RegExp(
    "^[" + c + "]+|[" + c + "]+$", "g"
  ), "");
};

const getBtcQrAddress =
  (wallet, amount, qr_pic_size, message) =>
    `https://chart.googleapis.com/chart?chs=${qr_pic_size}x${qr_pic_size}&cht=qr&chl=bitcoin:1${wallet}?amount=${amount}&message=${message}`;

export {
  generateToken,
  queryString,
  trimString,
  getBtcQrAddress,
}
