import hostConfig from './config';

const API_ENDPOINT = process.env.NODE_ENV === 'production' ? hostConfig.prodBackend : hostConfig.devBackend;
const SHA_CODE = 'sdfasdf234021u34012340821903840210490821908349029048903';

const LOCAL_STORAGE_KEY = 'BOQPOD_SITE_DATA';
const PHONE_DB_CODE = 'phone';
const GSMBOX_DB_CODE = 'gsmbox';

const CONTACT_TAB_PHONE = 'phone';
const CONTACT_TAB_ID = 'boqpod';
const CONTACT_TAB_EMAIL = 'email';

const CONTACT_BY_PHONE = 'phone';
const CONTACT_BY_BOQPOD_ID = 'boqpod';
const CONTACT_BY_EMAIL = 'email';

const ORDER_NEW = 100;
const ORDER_NOT_FULLY_PAID = 90;
const ORDER_COMPLETE = 110;
const ORDER_OVERPAID = 120;
const ORDER_CANCELLED = 130;
const ORDER_SHIPPED = 140;

const ORDER_STATUSES = {
  [ORDER_NEW]: 'Waiting for payment',
  [ORDER_NOT_FULLY_PAID]: 'Partially paid',
  [ORDER_COMPLETE]: 'Paid and waiting for shipment',
  [ORDER_OVERPAID]: 'Paid and waiting for shipment',
  [ORDER_CANCELLED]: 'Order cancelled',
  [ORDER_SHIPPED]: 'Order shipped',
};

export {
  API_ENDPOINT,
  LOCAL_STORAGE_KEY,
  PHONE_DB_CODE,
  GSMBOX_DB_CODE,
  CONTACT_TAB_EMAIL,
  CONTACT_TAB_ID,
  CONTACT_TAB_PHONE,
  CONTACT_BY_PHONE,
  CONTACT_BY_BOQPOD_ID,
  CONTACT_BY_EMAIL,
  ORDER_OVERPAID,
  ORDER_CANCELLED,
  ORDER_COMPLETE,
  ORDER_NOT_FULLY_PAID,
  ORDER_NEW,
  ORDER_STATUSES,
  ORDER_SHIPPED,
  SHA_CODE,
};
