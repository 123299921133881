import React, {Component} from 'react';
import { inject, observer } from "mobx-react";
import {getOrder} from "../../lib/order";
import { withRouter, Redirect } from 'react-router-dom';
import {CONTACT_TAB_EMAIL, ORDER_COMPLETE} from "../../constants";
import OrderConfirmed from "./OrderConfirmed";
import OrderShortInfo from "./OrderInfo/OrderShortInfo";
import OrderInfo from "./OrderInfo";

class OrderTracking extends Component {
  checkInterval = null;

  state = {
    redirectTo: null,
    order: {},
  };

  async componentDidMount() {
    const { store } = this.props;
    const orderNumber = this.props.match.params.order;
    store.isLoading = true;
    const order = await getOrder(orderNumber);
    store.isLoading = false;

    if (order === null) {
      this.setState({
        redirectTo: '/404',
      });
    }
    else {
      this.setState({
        order,
      });
      if (order.order_status < ORDER_COMPLETE) {
        this.checkInterval = setInterval(async () => {
          const order = await getOrder(orderNumber);
          this.setState({order});
        }, 5000);
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.checkInterval);
  }

  get checkData() {
    return Object.keys(this.state.order).length > 0;
  }

  render() {
    const {
      order,
      redirectTo,
    } = this.state;
    const { cryptos } = this.props.store;
    if (redirectTo) return <Redirect to={redirectTo}/>;
    if (!this.checkData) return null;
    return (
      <div className="confirmed__block">
        <OrderInfo
          order={order}
          currency={cryptos.find(c => c.currency_code === order.payment_type)}
        />
      </div>
    )
  }
}

export default withRouter(inject('store')(observer(OrderTracking)));
