import React, {Component} from 'react';
import { inject, observer } from "mobx-react";
import Slider from "../../Slider";
import {ArrowRightSmallPic, CheckPic} from "../../../constants/images";
import PlusMinus from "../../PlusMinus";
import AddToCartButton from "../AddToCartButton";
import DeliveryInfoBlock from "../DeliveryInfoBlock";
import PayWith from "../PayWith";
import ReturnPolicyPopup from "../StorePopup/ReturnPolicyPopup";
import numberFormatter from "number-formatter";
import {GSMBOX_DB_CODE} from "../../../constants";
import withHandleAddItem from "../../../decorators/withHandleAddItem";

const boxImages = [
  require('../../../images/bg/slider/box-1.jpg'),
  require('../../../images/bg/slider/box-2.jpg'),
  require('../../../images/bg/slider/box-3.jpg'),
];

class GsmBoxStoreBlock extends Component {
  state = {
    boxesCount: 1,
  };

  handleChangeBoxesCount = boxesCount => this.setState({ boxesCount });

  handleAddGsmBox = () => {
    this.props.handleAddItem(GSMBOX_DB_CODE, this.state.boxesCount);
    this.setState({ boxesCount: 1 });
  };

  render() {
    const {
      store,
      store: {
        cartAddButtons,
        storeItems,
      }
    } = this.props;

    return (
      <div className="store">
        <div className="store__wrapper">
          <Slider
            images={boxImages}
            title={(
              <>
                <h2>
                  {storeItems[GSMBOX_DB_CODE].item_name}
                </h2>
              </>
            )}
          />
          <div className="store__add-cart">
            <div className="store__title">
              <h2>
                {storeItems[GSMBOX_DB_CODE].item_name}
              </h2>
            </div>
            <div className="store__add-cart__wrap">
              <div className="store__price">
                ₣{numberFormatter('#,##0.00', storeItems[GSMBOX_DB_CODE].item_price)}
              </div>
              <div className="store__stock">
                <img src={CheckPic} alt=""/>In Stock
              </div>
              <div className="store__amount__wrap">
                <PlusMinus
                  value={this.state.boxesCount}
                  onChange={this.handleChangeBoxesCount}
                  min={1}
                />
              </div>
              <AddToCartButton
                loading={cartAddButtons[GSMBOX_DB_CODE]}
                onClick={this.handleAddGsmBox}
                title={'Add to cart'}
              />
              <DeliveryInfoBlock/>
              <PayWith/>
              <span
                className="open-popup-link"
                onClick={() => store.storePopup = <ReturnPolicyPopup/>}
              >Return policy<img
                src={ArrowRightSmallPic}
                alt=""/></span>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withHandleAddItem(inject('store')(observer(GsmBoxStoreBlock)));
