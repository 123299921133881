import React from 'react';
import {CheckPic} from "../constants/images";

const StockInfo = ({available}) => {
  return (
    available
      ?
      <div className="store__stock">
        <img src={CheckPic} alt=""/>In Stock
      </div>
      :
      <div className="store__stock store__stock--out-stock">
        Temporarily Out of Stock. Proceed to checkout and we’ll ship
        when available.
      </div>
  );
};

export default StockInfo;
