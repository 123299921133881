import React, {Component} from 'react';
import SecureCheckoutEstimatedTotal from "./SecureCheckoutEstimatedTotal";
import AnimateHeight from "react-animate-height";
import { inject, observer } from "mobx-react";
import {QuestionActivePic, QuestionPic} from "../../constants/images";

class ShippingMethodForm extends Component {
  state = {
    active: true,
  };

  handleToggleOpen = () => {
    this.setState({ active: !this.state.active });
  };

  handleChangeDelivery = ev => {
    const { store } = this.props;
    store.cart.delivery_id = parseInt(ev.target.value);
  };

  render() {
    const { active } = this.state;
    const { store } = this.props;
    const { i18n } = store;
    const del = store.deliveries.find(item => item.id === store.cart.delivery_id);

    return (
      <div className={`item item--shipping ${active ? 'active' : ''}`}>
        <div className="head" onClick={this.handleToggleOpen}>
          <div className="head__wrap">
            <div>
              <span>2.</span> {i18n('checkout', 'checkout_choose_shipping_method')}
            </div>
            <span className="span-edit">{i18n('checkout', 'checkout_edit')}</span>
          </div>
          <div className="head__block--edit">
            <div className="wrap">
              <p>{del.delivery_name} – ₣{del.delivery_price}</p>
              <p>({del.delivery_description})</p>
            </div>
          </div>
        </div>
        <AnimateHeight
          duration={500}
          height={active ? 'auto' : 0}
        >
          <div className="body">
            <ul>
              {
                store.deliveries.map((item, key) => {
                  return (
                    <li key={key}>
                      <label className="radio">
                        <input
                          type="radio"
                          name="delivery_id"
                          value={item.id}
                          onChange={this.handleChangeDelivery}
                          checked={store.cart.delivery_id === item.id}
                        />
                        <div className="radio__text">
                          <p>{item.delivery_name} – ₣{item.delivery_price}</p>
                          <span>({item.delivery_description})</span>
                        </div>
                        <div className="question-popover-wrap">
                          <img
                            className="question-popover-wrap__img"
                            src={QuestionPic}
                            alt=""/>
                          <img className="question-popover-wrap__img"
                               src={QuestionActivePic} alt=""/>
                          <span className="question-popover">
                                            <em>{item.delivery_comment}</em>
                                        </span>
                        </div>
                      </label>
                    </li>
                  );
                })
              }
            </ul>
          </div>
        </AnimateHeight>
        <SecureCheckoutEstimatedTotal/>
      </div>
    );
  }
}

export default inject('store')(observer(ShippingMethodForm));
