import React from 'react';
import { inject, observer } from "mobx-react";
import numberFormatter from "number-formatter";
import PropTypes from 'prop-types';

const FixedMobileEstimatedTotal = ({ store: { cartItemsCount, cartTotal }, renderButton }) => {
  return (
    <div className="total-block__total-cost__fixed">
      <div className="total-block__total-cost__wrap">
        <div className="total-block__total-cost">
          <div className="wrap">
            <p>Estimated total ({cartItemsCount} items): </p>
            <p className="price">₣{numberFormatter('#,##0.00', cartTotal)}</p>
          </div>
        </div>
        {renderButton()}
      </div>
    </div>
  );
};

FixedMobileEstimatedTotal.defaultProps = {
  renderButton: () => {}
};

FixedMobileEstimatedTotal.propTypes = {
  renderButton: PropTypes.func,
};

export default inject('store')(observer(FixedMobileEstimatedTotal));
