import React, {Component, Fragment} from 'react';
import {QuestionActivePic, QuestionPic} from "../../../../constants/images";
import ExpireDateSelector from "./ExpireDateSelector";

class PayByCreditCardTab extends Component {
  render() {
    return (
      <Fragment>
        <div className="select__wrap select__wrap--short success">
          <label>Credit or Debit Card Number</label>
          <input type="text"/>
          <div className="error">
            Number is required
          </div>
        </div>
        <ExpireDateSelector/>
        <div className="select__wrap select__wrap--short security-code success">
          <div className="label__wrap">
            <label>Security Code </label>
            <span className="question-popover-wrap"><img
              className="question-popover-wrap__img" src={QuestionPic} alt=""/>
                                        <img className="question-popover-wrap__img" src={QuestionActivePic}
                                             alt=""/>
                                        <span className="question-popover">
                                            <em>Courier will fly to any country for delivery. Minimum order – 5 items.</em>
                                        </span>
                                    </span>
          </div>
          <input type="text"/>

          <div className="error">
            Code is required
          </div>
        </div>
      </Fragment>
    );
  }
}

export default PayByCreditCardTab;
