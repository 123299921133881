import CheckPic from '../images/svg/check.svg';
import ArrowRightSmallPic from '../images/svg/arrow-right-small.svg';
import LoaderPic from '../images/svg/loader.svg';
import RecommendBoxPic from '../images/bg/recommend-box.jpg';
import ArrowDownPic from '../images/svg/arrow-down.svg';
import QuestionPic from '../images/svg/question.svg';
import QuestionActivePic from '../images/svg/question-active.svg';
import MessengerCirclePic from '../images/svg/messenger-circle.svg';
import NotesCirclePic from '../images/svg/notes-circle.svg';
import UpdatesPic from '../images/svg/updates.svg';
import FirewallPic from '../images/svg/firewall.svg';
import PrivacyPic from '../images/svg/privacy.svg';
import ToTop from '../images/svg/top.svg';
import ToTopActive from '../images/svg/top-active.svg';
import LogoSymbol from '../images/svg/logo-symbol.svg';
import ClosePic from '../images/svg/close.svg';
import CartPic from '../images/svg/cart.svg';
import { GSMBOX_DB_CODE } from "./index";
import LockPic from '../images/svg/lock.svg';
import LogoNoTagLinePic from '../images/svg/logo-no-tagline.svg';
import LogoPic from '../images/svg/logo.svg';
import ClockPic from '../images/svg/clock.svg';
import ZoomPic from '../images/svg/zoom.svg';

import GooglePlay from '../images/svg/googleplay-english.svg';
import AppStoreSoon from '../images/svg/appstore-english-soon.svg';
import DownloadApk from '../images/svg/downloadapk.svg';

import GsmBoxImage2 from '../images/bg/gsmbox-image-2.jpg';
import PhoneImage3 from '../images/bg/phone-image-3.png';

// background images
const loadBigImages = async () => {
  try {
    const res = await Promise.all([
      import ('../images/bg/phone-image-1.jpg'),
      import ('../images/bg/phone-image-2.png'),
      import ('../images/bg/phone-image-3.png'),
      import ('../images/bg/phone-image-4.png'),
      import ('../images/bg/phone-image-5.png'),

      import ('../images/bg/slider/phone-1.jpg'),

      import ('../images/bg/phone-image-2-mobile.jpg'),
      import ('../images/bg/phone-image-3-mobile.jpg'),
      import ('../images/bg/phone-image-4-mobile.jpg'),
      import ('../images/bg/phone-image-5-mobile.jpg'),

      import ('../images/bg/gsmbox-image-1.jpg'),
      import ('../images/bg/map.png'),
      import ('../images/bg/box.jpg'),
      import ('../images/bg/phone.jpg'),
      import ('../images/bg/phone-order.png'),
      import ('../images/bg/phone-mobile.png'),
      import ('../images/bg/recommend-box.jpg'),

      import ('../images/bg/messenger-image-1-mobile.png'),
      import ('../images/bg/messenger-image-1.png'),
      import ('../images/bg/messenger-image-2-mobile.png'),
      import ('../images/bg/messenger-image-2.png'),
      import ('../images/bg/messenger-image-3-1-mobile.png'),
      import ('../images/bg/messenger-image-3-1.png'),
      import ('../images/bg/messenger-image-3-2-mobile.png'),
      import ('../images/bg/messenger-image-4.jpg'),
      import ('../images/bg/messenger-image-5.png'),
    ]);
    return res;
  }
  catch (e) {
    console.error(e);
  }
};

const payWith = [
  require('../images/svg/pay-with-1.svg'),
  require('../images/svg/pay-with-2.svg'),
  require('../images/svg/pay-with-3.svg'),
  require('../images/svg/pay-with-4.svg'),
];

const numberPics = [
  require('../images/svg/number-1.svg'),
  require('../images/svg/number-2.svg'),
  require('../images/svg/number-3.svg'),
];

const langIcons = {
  ru: require('../images/svg/flag-ru.svg'),
  en: require('../images/svg/flag-en.svg'),
  es: require('../images/svg/flag-es.svg'),
  fr: require('../images/svg/flag-fr.svg'),
  ch: require('../images/svg/flag-ch.svg'),
};

const cryptoPics = {
  BTC: require('../images/svg/crypto/btc.svg'),
  XMR: require('../images/svg/crypto/xmr.svg'),
  XRP: require('../images/svg/crypto/xrp.svg'),
};

const notifyPics = {
  [GSMBOX_DB_CODE]: require('../images/bg/box-small.jpg'),
};

const ORDER_PICS = {
  'phone': require('../images/bg/phone-order.png'),
  'gsmbox': require('../images/bg/phone-order.png'),
};

export {
  CheckPic,
  ArrowRightSmallPic,
  LoaderPic,
  payWith,
  RecommendBoxPic,
  ArrowDownPic,
  QuestionPic,
  QuestionActivePic,
  MessengerCirclePic,
  NotesCirclePic,
  UpdatesPic,
  FirewallPic,
  PrivacyPic,
  numberPics,
  langIcons,
  ToTop,
  ToTopActive,
  LogoSymbol,
  GsmBoxImage2,
  ClosePic,
  CartPic,
  notifyPics,
  PhoneImage3,
  LockPic,
  LogoPic,
  LogoNoTagLinePic,
  ClockPic,
  ORDER_PICS,
  loadBigImages,
  ZoomPic,
  AppStoreSoon,
  GooglePlay,
  DownloadApk,
  cryptoPics,
};
