import {cancel_order, fetch_order} from "../api";
import appStore from "../store/app";

const getOrder = async order_number => {
  try {
    const res = await fetch_order(order_number);
    return res.data.data;
  }
  catch (e) {
    console.error(e);
    return null;
  }
};

const cancelOrder = async order_number => {
  try {
    const res = await cancel_order(order_number);
    return res;
  }
  catch (e) {
    console.error(e);
    return null;
  }
};

export {
  getOrder,
  cancelOrder
}
