import React from 'react';
import { inject, observer } from 'mobx-react';
import CloseButton from "./CloseButton";

const ReturnPolicyPopup = ({ store: { i18n }}) => (
  <div className="popup__wrapper mfp-close-btn-in">

    <div className="popup">
      <div className="popup__title">
        {i18n('return', 'return_exchanges')}
      </div>
      <p>
        {i18n('return', 'return_you_can')}
      </p>
      <p>
        {i18n('return', 'return_we_will')}
      </p>
      <p>
        {i18n('return', 'return_the_following')}
      </p>
      <p>
        {i18n('return', 'return_a_return')}
      </p>
      <CloseButton/>
    </div>
  </div>
);

export default inject('store')(observer(ReturnPolicyPopup));
