import React, {Component} from 'react';
import CustomSelector from "../../../Store/StorePopup/ShippingCostPopup/CustomSelector";
import moment from 'moment';

class ExpireDateSelector extends Component {
  state = {
    months: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
    ],
    years: [],
  };

  constructor(props) {
    super(props);
    const thisYear = parseInt(moment().format('YY'));
    for ( let i = 0 ; i < 10 ; i++ )
      this.state.years.push(thisYear + i);
  }

  handleYearChange = ev => {
    let months = [];
    const today = moment();
    console.log(ev.key, today.format('YY'));
    if (ev.key === parseInt(today.format('YY'))) {
      for ( let i = parseInt(today.format('M')) ; i < 13 ; i++ )
        months.push(i);
      console.log(months);
      this.setState({ months });
    }
  };

  render() {
    const { months, years } = this.state;
    return (
      <div
        className="select__wrap select__wrap-2 select__wrap--short select__wrap--date not-mob success">
        <div className="wrapper">
          <div className="wrap">
            <label>Expiration Date</label>
            <div className="wrap-2">
              {/*добавить класс white к <div class="select__head> когда выбрана дата*/}
              <CustomSelector
                items={months.map(month => ({
                  key: month,
                  title: month < 10 ? `0${month}` : month,
                }))}
                onSelect={() => {}}
                wrapperClasses={'select__wrapper--date'}
              />
              <span className="sep"> / </span>
              <CustomSelector
                items={years.map(year => ({
                  key: year,
                  title: year,
                }))}
                onSelect={this.handleYearChange}
                wrapperClasses={'select__wrapper--date'}
              />
            </div>
          </div>
        </div>
        <div className="error">
          Date is required
        </div>
      </div>
    );
  }
}

export default ExpireDateSelector;
