import Home from "../pages/Home";
import GsmBox from "../pages/GsmBox";
import Store from "../pages/Store";
import Terms from "../pages/Terms";
import Messenger from "../pages/Messenger";
import StorePhone from "../pages/StorePhone";
import StoreGsmBox from "../pages/StoreGsmBox";
import Cart from "../pages/Cart";
import Checkout from "../pages/Checkout";
import Payment from "../pages/Payment";
import Order from "../pages/Order";
import Subscription from "../pages/Subscription";

const menuRoutes = [
  {
    path: '/',
    exact: true,
    component: Home,
    name: 'Phone',
  },
  {
    path: '/gsmbox',
    component: GsmBox,
    name: 'GSM Box',
  },
  {
    path: '/messenger',
    component: Messenger,
    name: 'Messenger',
  },
  {
    path: '/subscription',
    component: Subscription,
    name: 'Subscription',
  },
  {
    path: '/store',
    component: Store,
    name: 'Store',
  },
  {
    path: '/terms',
    component: Terms,
    name: 'Terms of Use',
  },
];

const siteRoutes = [
  {
    path: '/terms',
    component: Terms,
    name: 'Terms of Use',
  },
  {
    path: '/store/phone',
    component: StorePhone,
  },
  {
    path: '/store/gsmbox',
    component: StoreGsmBox,
  },
  {
    path: '/privacy',
    component: Terms,
    name: 'Privacy Police',
  },
];

const cartRoutes = [
  {
    path: '/cart/:cart',
    component: Cart,
  },
  {
    path: '/checkout/:cart',
    component: Checkout,
  }
];

const orderRoutes = [
  {
    path: '/payment/:order',
    component: Payment,
  },
  {
    path: '/order/:order',
    component: Order,
  }
];

export {
  menuRoutes,
  siteRoutes,
  cartRoutes,
  orderRoutes,
};
