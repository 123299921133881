import React, {Component} from 'react';
import {ClockPic} from "../../../constants/images";
import OrderInfo from "../OrderInfo";
import { inject, observer } from "mobx-react";
import {getBtcQrAddress} from "../../../lib";
import {cancelOrder, getOrder} from "../../../lib/order";
import { withRouter, Redirect } from 'react-router-dom';
import OrderConfirmed from "../OrderConfirmed";
import moment from "moment";
import {ORDER_COMPLETE, ORDER_NEW} from "../../../constants";

class CryptoPayment extends Component {
  state = {
    qrCode: null,
    redirectTo: null,
    order: {},
    orderConfirmed: false,
    secondsToCancel: 3600,
    currency: '',
  };

  checkInterval = null;
  btcFixInterval = null;

  async componentDidMount() {
    const { store } = this.props;
    const orderNumber = this.props.match.params.order;
    store.isLoading = true;
    const order = await getOrder(orderNumber);
    store.isLoading = false;
    const { cryptos } = store;

    if (order === null) {
      this.setState({
        redirectTo: '/404',
      });
    }
    else {
      const currency = cryptos.find(item => item.currency_code === order.payment_type);
      const finishTm = moment(order.created_at).add(1, 'hour').unix();
      const now = moment().unix();
      this.setState({
        secondsToCancel: finishTm - now,
        currency,
      });
      this.btcFixInterval = setInterval(
        async () => {
          const { secondsToCancel } = this.state;
          if (secondsToCancel < 1) {
            clearInterval(this.btcFixInterval);
            await this.handleCancelOrder();
          }
          else {
            this.setState({
              secondsToCancel: secondsToCancel - 1
            });
          }
        }, 1000);

      this.setState({
        qrCode: getBtcQrAddress(
          order.wallet_address,
          order.order_amount,
          130,
          `Payment for order #${orderNumber}`),
        order,
      });
      if (order.order_status < ORDER_COMPLETE) {
        this.checkInterval = setInterval(async () => {
          const order = await getOrder(orderNumber);
          this.setState({order});
        }, 5000);
      }
      else {
        this.setState({
          redirectTo: `/order/${orderNumber}`,
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { order } = this.state;
    if (Object.keys(prevState.order).length > 0 && prevState.order.order_status !== order.order_status) {
      // console.log(order);
      if (order.order_status > ORDER_NEW) {
        clearInterval(this.checkInterval);
        this.setState({
          orderConfirmed: true,
        });
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.checkInterval);
    clearInterval(this.btcFixInterval);
  }

  get checkData() {
    return Object.keys(this.state.order).length > 0;
  }

  renderBtcFixTime = () => {
    const { secondsToCancel, currency: { currency_name } } = this.state;
    const minutes = Math.floor(secondsToCancel / 60);
    const seconds = secondsToCancel % 60;
    const timeLeft = [minutes, seconds].map(item => item < 10 ? '0' + item : item);
    return (
      <p><img src={ClockPic} alt=""/>{currency_name} exchange rate is fixed for {timeLeft[0]}m : {timeLeft[1]}s</p>
    );
  };

  handleCancelOrder = async () => {
    const { order } = this.state;
    const { store } = this.props;
    store.isLoading = true;
    const res = await cancelOrder(order.order_number);
    this.setState({
      redirectTo: '/store',
    });
    store.isLoading = false;
  };


  mapPaymentInfo = () => {
    const { order, currency: { currency_name, currency_short_name } } = this.state;
    return (
      this.checkData
        ?
        <div className="waiting__block waiting__block--pay">
          <h2>Pay with {currency_name}</h2>
          <p>Use this QR code or {currency_name} address below to pay</p>
          <img className="waiting__block--pay__img" src={this.state.qrCode} alt=""/>
          <p>{order.wallet_address}</p>
          <span className="btn"><a style={{ color: 'white' }} href={`bitcoin:${order.wallet_address}&amount=${order.order_amount}&label=boqpod&message=Order+#${order.order_number}`}>Pay {order.order_amount} {currency_short_name}</a></span>
          {this.renderBtcFixTime()}
          <span onClick={this.handleCancelOrder}>Cancel order</span>
        </div>
        :
        null
    );
  };

  render() {
    const { redirectTo, order, orderConfirmed } = this.state;
    const { cryptos } = this.props.store;
    if (redirectTo) return <Redirect to={redirectTo}/>;
    if (!this.checkData) return null;
    return (
      !orderConfirmed
        ?
        <div className="confirmed__block">
          {this.mapPaymentInfo()}
          <OrderInfo
            order={order}
            currency={cryptos.find(c => c.currency_code === order.payment_type)}
          />
        </div>
        :
        <div className="confirmed__block">
          <OrderConfirmed order={order}/>
        </div>
    );
  }
}

export default withRouter(inject('store')(observer(CryptoPayment)));
