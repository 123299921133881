import React, {PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import {CONTACT_TAB_EMAIL, ORDER_STATUSES} from "../../../constants";
import {ORDER_PICS} from "../../../constants/images";
import numberFormatter from 'number-formatter';

class OrderInfo extends PureComponent {

  mapItems = () => {
    const { order: { cart_cast: { items } } } = this.props;
    return items.map((item, key) => (
      <div className="check__item" key={key}>
        <div className="wrap">
          <div className="check__item__img">
            <img className="check__item__img__img" src={ORDER_PICS[item.item_code]} alt=""/>
          </div>
          <div className="check__item__title">
            {item.item_name}
          </div>
        </div>
        <div className="wrap wrap--mobile">

          <div className="check__item__price ">
            <span>Price per item:</span>₣ {numberFormatter('#,##0.00', item.item_price)}
          </div>
          <div className="check__item__count">
            <span>Qty:</span>{item.count}
          </div>
          <div className="inner-wrap">
            <div className="check__item__title check__item__title--mobile">
              {item.item_name}
            </div>
            <div className="check__item__total">
              ₣ {numberFormatter('#,##0.00', item.item_price * item.count)}
            </div>
          </div>

        </div>
      </div>
    ))
  };

  mapTotals = () => {
    const {
      currency,
      order: {
        order_amount,
        order_status,
        cart_cast: {
          items, country, delivery
        },
        user: {
          full_name,
          address,
          city,
          state,
          zip,
          email,
          phone_number,
        },
      }
    } = this.props;
    const subtotal = items.map(item => item.count * item.item_price).reduce((a, b) => a + b);
    return (
      <div className="check__total">
        <div className="check__total__line">
          <div className="wrap">
            <p>Subtotal ({items.map(item => item.count).reduce((a, b) => a + b)} items)</p>
            <p>₣ {numberFormatter('#,##0.00', subtotal)}</p>
          </div>
          <div className="wrap">
            <p>Courier delivery to {country.country_name}</p>
            <p>₣ {numberFormatter('#,##0.00', delivery.delivery_price)}</p>
          </div>
          {/*<div className="wrap">*/}
          {/*    <p>Taxes</p>*/}
          {/*    <p>₣100.00</p>*/}
          {/*</div>*/}
        </div>
        <div className="check__total__line">
          <div className="wrap">
            <p>Order Total</p>
            <p>₣ {numberFormatter('#,##0.00', subtotal + delivery.delivery_price)}
              <span>{order_amount} {currency.currency_short_name}</span>
            </p>
          </div>
        </div>
        <div className="check__total__line">
          <div className="wrap">
            <p>Paid by</p>
            <p>{currency.currency_name}
              <span className="waiting">{ORDER_STATUSES[order_status]}</span>
            </p>
          </div>
        </div>
        <div className="check__total__line">
          <div className="wrap">
            <p>Ship to</p>
            <p>{full_name}<br/>
              {[address, zip + ' ' + city, state || '', country.country_name].filter(item => item.length > 0).join(', ')}<br/>

              <span>{email !== null && email}<br/>
                {phone_number !== null && phone_number}</span>
            </p>
          </div>
        </div>
      </div>

    );
  };

  render() {
    const {
      order: {
        order_number,
        created_at,
        order_status,
        user,
      }
    } = this.props;
    const createdAt = moment(created_at).format('MMMM Do YYYY, H:mm');
    return (
      <Fragment>
        <h2> Order #{order_number}</h2>
        <p>Remember this page url. You can check your order status by this url</p>
        {/*{user.contact_by === CONTACT_TAB_EMAIL &&*/}
        {/*<p>A copy of this order confirmation has been sent to your email address,&nbsp;*/}
        {/*  <a href={`mailto:${user.email}`}>{user.email}</a>*/}
        {/*</p>*/}
        {/*}*/}
        <ul className="confirmed__track">

          <li><p>Order number:</p><span>{order_number}</span></li>
          <li><br/></li>
          <li><p>Order Date:</p><span>{createdAt}</span></li>
          <li><p>Status:</p><span>{ORDER_STATUSES[order_status]}</span></li>
        </ul>
        <div className="check__item__wrapper">
          {this.mapItems()}
          {this.mapTotals()}
        </div>
      </Fragment>
    );
  }
}

OrderInfo.propTypes = {
  order: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
};

export default OrderInfo;
