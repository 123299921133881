import React, {Component} from 'react';
import { inject, observer } from "mobx-react/custom";
import PageContainer from "../components/PageContainer";
import Header from "../components/Header";
import NavLine from "../components/Header/NavLine";
import Footer from "../components/Footer";
import GsmBoxStoreBlock from "../components/Store/GsmBoxStoreBlock";
import MapNotifications from "../components/Store/MapNotifications";

class StoreGsmBox extends Component {

  componentDidMount() {
    const { store } = this.props;
    store.storePopup = null;
  }

  render() {
    const {
      store: {
        i18n, storePopup,
      }
    } = this.props;

    return (
      <PageContainer>
        <Header/>
        <div className="container">
          <MapNotifications/>
          <div className="wrap-bg">

            <NavLine/>

            <GsmBoxStoreBlock/>
            <div className="features">
              <p>{i18n('buygsmbox', 'buygsmbox_forwarding_incoming')}</p>
              <div className="features__block">
                <h2>{i18n('buygsmbox', 'buygsmbox_features')}</h2>
                <ul>
                  <li>
                    <h3>{i18n('buygsmbox', 'buygsmbox_access_to_your')}</h3>
                    <p>{i18n('buygsmbox', 'buygsmbox_call_to_the_sim')}</p>
                  </li>
                  <li>
                    <h3>{i18n('buygsmbox', 'buygsmbox_display')}</h3>
                    <p>{i18n('buygsmbox', 'buygsmbox_a_small')}</p>
                  </li>
                </ul>
                <div className="features__in-box">
                  <h2>{i18n('buygsmbox', 'buygsmbox_whats_in_the_box')}</h2>
                  <ul>
                    <li>
                      <p>{i18n('buygsmbox', 'buygsmbox_gsmbox')}</p>
                    </li>
                    <li>
                      <p>{i18n('buygsmbox', 'buygsmbox_usb_c')}</p>
                    </li>
                    <li>
                      <p>{i18n('buygsmbox', 'buygsmbox_usb_c_power')}</p>
                    </li>
                  </ul>
                </div>
              </div>

            </div>

          </div>
        </div>
        <Footer/>
        {storePopup}

      </PageContainer>
    );
  }
}

export default inject('store')(observer(StoreGsmBox));
