const installFrames = [
  require('../../../images/how_to_install/how-to-install-01.jpg'),
  require('../../../images/how_to_install/how-to-install-02.jpg'),
  require('../../../images/how_to_install/how-to-install-03.jpg'),
  require('../../../images/how_to_install/how-to-install-04.jpg'),
  require('../../../images/how_to_install/how-to-install-05.jpg'),
  require('../../../images/how_to_install/how-to-install-06.jpg'),
  require('../../../images/how_to_install/how-to-install-07.jpg'),
  require('../../../images/how_to_install/how-to-install-08.jpg'),
  require('../../../images/how_to_install/how-to-install-09.jpg'),
];

export {
  installFrames,
};
