import React from 'react';
import PageContainer from "../components/PageContainer";
import Header from "../components/Header";
import Footer from "../components/Footer";
import NavLine from "../components/Header/NavLine";
import Tabs from "../components/Tabs";
import Accordion from "../components/Accordion";
import MessengerDescription from "../components/MessengerDescription";
import { AppStoreSoon, DownloadApk, GooglePlay } from "../constants/images";
import ScrollableAnchor from 'react-scrollable-anchor'
import MessengerHowToInstall from '../components/MessengerHowToInstall';
import { inject, observer } from 'mobx-react';

const Messenger = ({ store: { i18n }}) => (
  <PageContainer>
    <Header/>
    <div className="container">
      <div className="wrap-bg">

        <NavLine/>

        <div className="download-block">
          <div className="download-block__wrap">
            <div className="wrap">
              <h2>{i18n('messenger', 'messenger_one_of_the_best')}</h2>
              <div className="btn-line">
                <span className="btn-line__item"><a href="https://play.google.com/store/apps/details?id=com.boqpod.msg"><img src={GooglePlay}
                                                                 alt=""/></a></span>
                <span className="btn-line__item"><img src={AppStoreSoon} alt=""/></span>
                <span className="btn-line__item"><a href={`https://www.boqpod.ch/download/boqpod.apk?rnd=${Math.random()}`}><img src={DownloadApk} alt=""/></a></span>
              </div>
            </div>
            <ul>
              <li>
                <a href="#features">{i18n('messenger', 'messenger_private_messages')}</a>
                <a href="#features">{i18n('messenger', 'messenger_virtual_boqpod')}</a>
                <a href="#features">{i18n('messenger', 'messenger_end_to_end')}</a>
              </li>
              <li>
                <a href="#features">{i18n('messenger', 'messenger_audio_and_video_calls')}</a>
                <a href="#features">{i18n('messenger', 'messenger_p2p_connections')}</a>
                <a href="#features">{i18n('messenger', 'messenger_obscure_chats')}</a>
              </li>
              <li>
                <a href="#features">{i18n('messenger', 'messenger_panic_pin')}</a>
                <a href="#features">{i18n('messenger', 'messenger_no_logging')}</a>
              </li>

            </ul>
          </div>
        </div>
      </div>
      <div className="description-block description-block--messenger description-block--messenger-1">
        <div className="wrap">
          <span>{i18n('messenger', 'messenger_security_as')}</span>
          <h2>{i18n('messenger', 'messenger_true_implementation')}</h2>
          <p>{i18n('messenger', 'messenger_with_end_to_end')}</p>
          <a href="#features" className="btn">{i18n('messenger', 'messenger_read_more')}</a>
        </div>
      </div>

      <MessengerDescription/>

      <div className="description-block description-block--messenger description-block--messenger-3">
        <div className="wrap">
          <span>{i18n('messenger', 'messenger_anonymity_as')}</span>
          <h2>{i18n('messenger', 'messenger_full_anonymity')}</h2>
          <p>{i18n('messenger', 'messenger_we_dont_use')}</p>
          <a href="" className="btn">{i18n('messenger', 'messenger_read_more')}</a>
        </div>
      </div>
      <div className="overview-block">
        <h2><div dangerouslySetInnerHTML={{ __html: i18n('messenger', 'messenger_why_should_you') }} /></h2>
        <div className="text-block">
          <p>
            {i18n('messenger', 'messenger_boqpod_messenger_is')}
          </p>
        </div>
        <ScrollableAnchor id={'features'}><div/></ScrollableAnchor>
        <Tabs
          tabs={[
            {
              header: i18n('messenger', 'messenger_features'),
              content:
                <Accordion
                  items={[
                    {
                      header: i18n('messenger', 'messenger_private_messages'),
                      body: i18n('messenger', 'messenger_all_messages_are_protected'),
                    },
                    {
                      header: i18n('messenger', 'messenger_virtual_boqpod_id'),
                      body: i18n('messenger', 'messenger_the_unique'),
                    },
                    {
                      header: i18n('messenger', 'messenger_end_to_end'),
                      body: i18n('messenger', 'messenger_true_implementation_of_end')
                    },
                    {
                      header: i18n('messenger', 'messenger_audio_and_video_calls'),
                      body: i18n('messenger', 'messenger_you_can_call'),
                    },
                    {
                      header: i18n('messenger', 'messenger_seed_phrase'),
                      body: i18n('messenger', 'messenger_in_order'),
                    },
                    {
                      header: i18n('messenger', 'messenger_p2p_connections'),
                      body: i18n('messenger', 'messenger_any_server_in_boqpod'),
                    },
                    {
                      header: i18n('messenger', 'messenger_obscure_chats'),
                      body: i18n('messenger', 'messenger_group_chats_work'),
                    },
                    {
                      header: i18n('messenger', 'messenger_panic_pin'),
                      body: i18n('messenger', 'messenger_if_you_are'),
                    },
                    {
                      header: i18n('messenger', 'messenger_no_logging'),
                      body: i18n('messenger', 'messenger_no_metadata'),
                    },
                  ]}
                />
            },
            {
              header: i18n('messenger', 'messenger_security'),
              content:
                <>
                  <Accordion
                    title={i18n('messenger', 'messenger_private_messages')}
                    items={[
                      {
                        header: i18n('messenger', 'messenger_is_messaging'),
                        body: i18n('messenger', 'messenger_yes_the_correspondence'),
                      },
                      {
                        header: i18n('messenger', 'messenger_what_types'),
                        body: i18n('messenger', 'messenger_text_messages'),
                      },
                      {
                        header: i18n('messenger', 'messenger_is_my_online'),
                        body: i18n('messenger', 'messenger_no_one_can_see'),
                      },
                      {
                        header: i18n('messenger', 'messenger_is_it_possible'),
                        body: i18n('messenger', 'messenger_you_can_delete'),
                      },
                      {
                        header: i18n('messenger', 'messenger_how_can_i'),
                        body: i18n('messenger', 'messenger_as_with_any_other'),
                      },
                      {
                        header: i18n('messenger', 'messenger_how_does'),
                        body: i18n('messenger', 'messenger_you_send_an'),
                      },
                      {
                        header: i18n('messenger', 'messenger_are_audio'),
                        body: i18n('messenger', 'messenger_they_re_safe'),
                      },
                      {
                        header: i18n('messenger', 'messenger_what_about'),
                        body: i18n('messenger', 'messenger_app_supports'),
                      },
                    ]}
                  />
                  <Accordion
                    title={i18n('messenger', 'messenger_obscure_chats')}
                    items={[
                      {
                        header: i18n('messenger', 'messenger_how_do_secret'),
                        body: i18n('messenger', 'messenger_group_chats_work_in'),
                      },
                      {
                        header: i18n('messenger', 'messenger_how_can_i_restore'),
                        body: i18n('messenger', 'messenger_if_your_device'),
                      },
                    ]}
                  />
                </>
            }
          ]}
        />
      </div>
      <ScrollableAnchor id={'how_to_install'}><div/></ScrollableAnchor>

      <MessengerHowToInstall/>
    </div>
    <Footer/>
  </PageContainer>
);

export default inject('store')(observer(Messenger));
