import React from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import {ORDER_STATUSES} from "../../../constants";

const OrderShortInfo = ({ order }) => {
  const {
    order_number,
    created_at,
    order_status,
  } = order;
  const createdAt = moment(created_at).format('MMMM Do YYYY, H:mm');
  return (
    <ul className="confirmed__track">

      <li><p>Order number:</p><span>{order_number}</span></li>
      <li><br/></li>
      <li><p>Order Date:</p><span>{createdAt}</span></li>
      <li><p>Status:</p><span>{ORDER_STATUSES[order_status]}</span></li>
    </ul>
  );
};

OrderShortInfo.propTypes = {
  order: PropTypes.object.isRequired,
};

export default OrderShortInfo;
