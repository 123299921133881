import React from 'react';
import PageContainer from "../PageContainer";
import { ReactComponent as Space } from '../../images/svg/space.svg';

const GlobalError = () => {
  return (
    <PageContainer>
      <div className="container">
        <div className="error__block">
          <Space/>
          <h2>Something went wrong,</h2>
          <p>definitely we are already working on it and soon everything will be fixed.</p>
        </div>
      </div>
    </PageContainer>
  );
};

export default GlobalError;
