import React, {Component} from 'react';
import {NavLink, Link} from "react-router-dom";
import {menuRoutes} from "../../routes";
import CartBlock from "./CartBlock";
import withSticky from "../../decorators/withSticky";
import {LogoPic, LogoNoTagLinePic} from "../../constants/images";
import {inject, observer} from "mobx-react";

class Header extends Component {
  handleMenuToggle = () => {
    const { store } = this.props;
    store.mobileMenuOpen = !store.mobileMenuOpen;
  };

  render() {
    const { mobileMenuClass } = this.props.store;
    return (
      <header className={`header ${this.props.sticky && 'sticky'}`}>
        <div className="header__wrap">
          <div className={`header__m-menu ${mobileMenuClass}`} onClick={this.handleMenuToggle}>
          </div>
          <div className="header__logo__sticky">
            <Link to={'/'}><img src={LogoNoTagLinePic} className="header__logo__sticky__img" alt={''}/></Link>
          </div>
          <div className="header__logo">
            <Link to={"/"}>
              <img src={LogoPic} className="header__logo__img" alt={''}/>
              <img src={LogoNoTagLinePic} className="header__logo__img" alt={''}/>
            </Link>
            <nav className="nav">
              {menuRoutes.map((route, key) => {
                return (
                  <NavLink to={route.path} exact={route.exact} key={key} activeClassName={'active'}>{route.name}</NavLink>
                );
              })}
            </nav>
          </div>
          <CartBlock/>
        </div>
      </header>
    );
  }
}

export default withSticky(inject('store')(observer(Header)));
