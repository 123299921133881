import React, {Component} from 'react';

const withSticky = OriginalComponent => {
  return class DecoratedComponent extends Component {
    state = {
      sticky: false,
    };

    handleScroll = () => {
      const yOffset = window.pageYOffset || document.documentElement.scrollTop;
      this.setState({
        sticky: window.innerWidth >= 768 && yOffset > 40
      });
    };

    componentDidMount() {
      window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
    }

    render() {
      return <OriginalComponent {...this.state} {...this.props}/>
    }
  }
};

export default withSticky;
