import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {ArrowDownPic} from "../../../../constants/images";

class CustomSelector extends Component {

  constructor(props) {
    super(props);
    let keyActive = null;
    if (props.keyActive !== undefined)
      keyActive = props.keyActive;
    else
      keyActive = props.items[0].key;
    this.state = {
      opened: false,
      keyActive
    };
  }

  handleSelect = item => {
    const { onSelect } = this.props;
    this.setState({
      opened: false,
      keyActive: item.key,
    });
    onSelect(item);
  };

  render() {
    const { opened, keyActive } = this.state;
    const { items, wrapperClasses } = this.props;

    const item = items.find(item => item.key === keyActive);

    return (
      <div className={`select__wrapper ${wrapperClasses}`}>
        <div
          className={`select__head ${opened ? 'active' : ''}`}
          onClick={() => this.setState({ opened: !opened })}
        >
          {item.title} <img className="select__head__img" src={ArrowDownPic} alt=""/>
        </div>
        {opened &&
        <div className="select__list" style={{ height: 300, overflow: 'scroll' }}>
          {
            items.filter(item => item.key !== keyActive).map((item, key) => {
              return (
                <span
                  key={key}
                  onClick={() => this.handleSelect(item)}
                >
                                {item.title}
                            </span>
              );
            })
          }
        </div>
        }
      </div>
    );
  }
}

CustomSelector.propTypes = {
  items: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  keyActive: PropTypes.any,
  wrapperClasses: PropTypes.string,
};

export default CustomSelector;
