import React, {Component} from 'react';
import ShippingAddressForm from "./ShippingAddressForm";
import ShippingMethodForm from "./ShippingMethodForm";
import EstimatedTotal from "../ShoppingCart/EstimatedTotal";
import PaymentMethodForm from "./PaymentMethodForm";
import { inject, observer } from "mobx-react";
import {makeOrder} from "../../lib/cart";
import { Redirect} from 'react-router-dom';
import FixedMobileEstimatedTotal from "./SecureCheckoutEstimatedTotal/FixedMobileEstimatedTotal";

class SecureCheckout extends Component {
  orderNumber = '';

  state = {
    redirectToPayment: false,
  };

  handlePayment = async () => {
    const { store } = this.props;
    const result = await makeOrder(store.cart.cart_token);
    if (result) {
      this.orderNumber = result.order_number;
      this.setState({redirectToPayment: true});
    }
  };

  componentWillUnmount() {
    this.props.store.shippingFormValid = false;
  }

  render() {
    const { shippingFormValid, i18n } = this.props.store;
    const { redirectToPayment } = this.state;
    if (redirectToPayment) {
      return <Redirect to={`/payment/${this.orderNumber}`}/>;
    }
    return (
      <div className="container">
        <div className="accordion--checkout__wrap">
          <div className="accordion accordion--checkout">
            <ShippingAddressForm/>
            <ShippingMethodForm/>
            <PaymentMethodForm/>
          </div>
          <EstimatedTotal
            wrapperClasses={'total-block__wrap--checkout'}
            hidePolicy={true}
            renderButton={() => (
              shippingFormValid
                ?
                <span
                  className="btn button"
                  onClick={this.handlePayment}
                >
                                {i18n('checkout', 'checkout_go_to_payment')}
                            </span>
                :
                <span
                  className="btn button"
                  style={{
                    background: '#7f7f7f',
                  }}
                >
                                {i18n('checkout', 'checkout_go_to_payment')}
                            </span>
            )}
            disablePopups={true}
          />
        </div>
        <FixedMobileEstimatedTotal
          renderButton={() => (
            shippingFormValid
              ?
              <span
                className="btn button"
                onClick={this.handlePayment}
              >
                                {i18n('checkout', 'checkout_go_to_payment')}
                            </span>
              :
              null
          )}
        />
      </div>
    );
  }
}

export default inject('store')(observer(SecureCheckout));
