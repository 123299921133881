import React, {Suspense} from 'react';
import Header from "../components/Header";
import PageContainer from "../components/PageContainer";
import Footer from "../components/Footer";
import Loader from "../components/PageContainer/Loader";
const HomePage = React.lazy(() => import("../components/HomePage"));

const Home = () => (
  <Suspense fallback={<Loader/>}>
    <PageContainer>
      <Header/>
      <HomePage/>
      <Footer/>
    </PageContainer>
  </Suspense>
);

export default Home;
