import React from 'react';
import { inject, observer } from "mobx-react";
import PageContainer from "../components/PageContainer";
import Header from "../components/Header";
import NavLine from "../components/Header/NavLine";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowRight } from '../images/svg/arrow-right-small.svg';

const Store = ({ store: { i18n }}) => (
  <PageContainer>
    <Header/>
    <div className="container">
      <div className="wrap-bg">

        <NavLine/>

        <div className="description-block description-block--store description-block--store-1">
          <div className="wrap">
            <span>{i18n('store', 'store_secure_phone')}</span>
            <h2>{i18n('store', 'store_a_new_way')}</h2>
            <Link to={'/'}>{i18n('store', 'store_learn_more_phone')}<ArrowRight/></Link>
            <Link to={'/store/phone'}><span className="btn">{i18n('store', 'store_buy_phone')}</span></Link>
          </div>
        </div>
        <div className="description-block description-block--store description-block--store-2">
          <div className="wrap">
            <h2>{i18n('store', 'store_gsm_box')}</h2>
            <p>{i18n('store', 'store_the_device')}</p>
            <Link to={'/gsmbox'}>{i18n('store', 'store_learn_more_gsmbox')} <ArrowRight/></Link>
            <Link to={'/store/gsmbox'} className="btn">{i18n('store', 'store_buy_gsmbox')}</Link>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </PageContainer>
);

export default inject('store')(observer(Store));
