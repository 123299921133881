import React, {Component, Fragment} from 'react';
import { inject, observer } from 'mobx-react';
import SecureCheckoutEstimatedTotal from "../SecureCheckoutEstimatedTotal";
import AnimateHeight from "react-animate-height";
import PayByCryptoTab from "./PayByCryptoTab";
import PayByCreditCardTab from "./PayByCreditCardTab/index";
import BillingAddressForm from "./BillingAddressForm";

class PaymentMethodForm extends Component {
  state = {
    active: true,
    currentPaymentTab: 0,
  };

  handleToggleOpen = () => {
    this.setState({ active: !this.state.active });
  };

  renderPaymentTabs = () => {
    const { currentPaymentTab } = this.state;
    const { store: { i18n } } = this.props;

    const tabs = [
//            <PayByCreditCardTab/>,
      <PayByCryptoTab/>,
    ];
    const titles = [
//            'Credit / Debit Card',
      i18n('checkout', 'checkout_crypto'),
    ];
    return (
      <Fragment>
        <div className="tabs-wrap">
          {titles.map((title, key) =>
            <div
              className={`tab ${currentPaymentTab === key ? 'active' : ''}`}
              key={key}
              onClick={() => this.setState({ currentPaymentTab: key })}
            >
              {title}
            </div>)
          }
        </div>
        {tabs.map((tab, key) => (
          <div className={`choose-contact__block ${currentPaymentTab === key ? 'active' : ''}`} key={key}>
            {tab}
          </div>
        ))}
        {/*{tabs[this.state.currentPaymentTab]}*/}
      </Fragment>
    );
  };

  render() {
    const {
      active,
      currentPaymentTab,
    } = this.state;

    const { store: { i18n } } = this.props;

    return (
      <div className={`item item--choose ${active ? 'active' : ''}`}>
        <div className="head" onClick={this.handleToggleOpen}>
          <div className="head__wrap">
            <div>
              <span>3.</span> {i18n('checkout', 'checkout_choose_payment')}
            </div>
          </div>
        </div>
        <AnimateHeight
          duration={500}
          height={active ? 'auto' : 0}
        >
          <div className="body">
            <div className="choose-contact">
              {this.renderPaymentTabs()}
              {/*{currentPaymentTab === 0 &&*/}
              {/*    <BillingAddressForm/>*/}
              {/*}*/}
            </div>
          </div>
        </AnimateHeight>
        <SecureCheckoutEstimatedTotal/>
      </div>

    );
  }
}

export default inject('store')(observer(PaymentMethodForm));
