import React, {Component} from 'react';
import AnimateHeight from 'react-animate-height';
import SecureCheckoutEstimatedTotal from "../SecureCheckoutEstimatedTotal";
import {trimString} from "../../../lib";
import { inject, observer } from "mobx-react";
import CustomSelector from "../../Store/StorePopup/ShippingCostPopup/CustomSelector";
import PropTypes from 'prop-types';
import {
  CONTACT_BY_PHONE,
  CONTACT_BY_BOQPOD_ID,
  CONTACT_BY_EMAIL,
} from "../../../constants";

class ShippingAddressForm extends Component {
  state = {
    active: true,
    fullName: '',
    fullNameState: '',
    address: '',
    addressState: '',
    city: '',
    cityState: '',
    zip: '',
    zipState: '',
    state: '',
    phoneNumber: '',
    phoneNumberState: '',
    email: '',
    emailState: '',
    boqpodID: '',
    boqpodIDState: '',
  };

  get isFormValid() {
    let contactValid = false;
    const {
      fullNameState,
      addressState,
      cityState,
      zipState,
      phoneNumberState,
      boqpodIDState,
      emailState,
    } = this.state;
    const { contact_by } = this.props.store.checkout;

    switch (contact_by) {
      case CONTACT_BY_PHONE:
        if (phoneNumberState === 'success')
          contactValid = true;
        break;
      case CONTACT_BY_BOQPOD_ID:
        if (boqpodIDState === 'success')
          contactValid = true;
        break;
      case CONTACT_BY_EMAIL:
        if (emailState === 'success')
          contactValid = true;
        break;
    }
    return (
      fullNameState === 'success' &&
      addressState === 'success' &&
      cityState === 'success' &&
      zipState === 'success' &&
      contactValid
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { store } = this.props;
    if (prevState !== this.state) {
      store.shippingForm = {...this.state};
      store.shippingFormValid = this.isFormValid;
    }
  }

  handleToggleOpen = () => {
    this.setState({ active: !this.state.active });
  };

  handleInputChange = ({ target: { name, value }}) => {
    this.setState({
      [name]: value,
      [name + 'State']: value.length < 1 ? 'error' : 'success',
    });
  };

  handleZipChange = ({ target: { value, validity: { valid }}}) => {
    if (valid) {
      this.setState({
        zip: value,
        zipState: value.length < 1 ? 'error' : 'success',
      });
    }
  };

  handlePhoneNumberChange = ({ target: { value, validity: { valid }}}) => {
    if (this.state.phoneNumber.length === 0 && !isNaN(value))
      valid = true;
    if (valid) {
      this.setState({
        phoneNumber: value.length > 0 ? '+' + trimString(value, '+') : value,
        phoneNumberState: value.length < 1 ? 'error' : 'success',
      });
    }
  };

  handleEmailChange = ({ target: { value, validity: { valid }}}) => {
    const { contact_by } = this.props.store.checkout;
    this.setState({
      email: value,
      emailState: valid ? 'success' : contact_by === CONTACT_BY_EMAIL ? 'error' : '',
      // emailState: value.length > 0 ? valid ? 'success' : 'error' : contact_by === CONTACT_BY_EMAIL ? 'error' : '',
    });
  };

  inputError = props =>
    <div className="error">
      {props.text}
    </div>;

  inputWithChecking = ({ title, name, errorText, short, onChange, inputProps }) =>
    <div className={`select__wrap ${short === true ? 'select__wrap--short' : ''} ${this.state[name + 'State']}`}>
      <label>{title}</label>
      <input
        type="text"
        value={this.state[name]}
        name={name}
        onChange={onChange !== undefined ? onChange : this.handleInputChange}
        {...inputProps}
      />
      {this.state[name + 'State'] === 'error' &&
      <this.inputError text={errorText}/>
      }
    </div>;

  renderChooseContact = () => {
    const { emailState } = this.state;
    const { store, store: { checkout, i18n } } = this.props;

    const tabs = [
      <div className="choose-contact__block active">
        <this.inputWithChecking title={'boqpod ID'} name={'boqpodID'} errorText={'ID is required'}
                                short={true}/>
        {/*<div className={`select__wrap select__wrap--short ${emailState}`}>*/}
        {/*    <label className="email">E-mail <span>(Optional)</span></label>*/}
        {/*    <input type="email" name={'email'} value={this.state.email}*/}
        {/*           pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" onChange={this.handleEmailChange}/>*/}
        {/*</div>*/}
      </div>,
      <div className="choose-contact__block active">
        <this.inputWithChecking
          title={'E-mail'}
          name={'email'}
          errorText={i18n('checkout', 'checkout_email_required')}
          short={true}
          onChange={this.handleEmailChange}
          inputProps={{
            type: 'email',
            pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$",
          }}
        />
      </div>,
      <div className="choose-contact__block active">
        <this.inputWithChecking
          title={i18n('checkout', 'checkout_phone_number')}
          name={'phoneNumber'}
          errorText={i18n('checkout', 'checkout_phone_number_required')}
          short={true}
          onChange={this.handlePhoneNumberChange}
          inputProps={{
            type: 'tel',
            pattern: '^\\+\\d*',
          }}
        />
        <div className={`select__wrap select__wrap--short ${emailState}`}>
          <label className="email"><div dangerouslySetInnerHTML={{ __html: i18n('checkout', 'checkout_email_optional') }} /></label>
          <input type="email" name={'email'} value={this.state.email}
                 pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" onChange={this.handleEmailChange}/>
        </div>
      </div>,
    ];

    const titles = [
      'boqpod ID',
      'E-mail',
      i18n('checkout', 'checkout_phone_number'),
    ];

    const __t = [
      CONTACT_BY_BOQPOD_ID,
      CONTACT_BY_EMAIL,
      CONTACT_BY_PHONE,
    ];

    return (
      <div className="choose-contact">
        <div className="choose-contact__title">{i18n('checkout', 'checkout_choose_contact')}</div>
        <div className="tabs-wrap">
          {__t.map((tab, key) =>
            <div
              key={key}
              className={`tab ${checkout.contact_by === tab && 'active'}`}
              onClick={() => {
                checkout.contact_by = tab;
                store.shippingFormValid = this.isFormValid;
              }}
            >
              {titles[key]}
            </div>)
          }
        </div>
        {tabs[__t.indexOf(checkout.contact_by)]}
      </div>
    );
  };

  render() {
    const {
      active,
      fullName,
      address,
      zip,
      state,
      zipState,
      city,
      email,
      phoneNumber,
    } = this.state;

    const { store } = this.props;
    const { i18n } = store;
    const { country_name } = store.countries.find(item => item.country_code === store.cart.country_code);

    return (
      <div className={`item item--enter ${active ? 'active' : ''}`}>
        <div className="head" onClick={this.handleToggleOpen}>
          <div className="head__wrap">
            <div>
              <span>1.</span> {i18n('checkout', 'checkout_enter_shipping')}
            </div>
            <span className="span-edit">{i18n('checkout', 'checkout_edit')}</span>
          </div>
          <div className="head__block--edit">
            <div className="wrap">
              <p>{fullName}</p>
              <p>{[address, [zip, city].filter(item => item.length > 0).join(' '), state, country_name].filter(item => item.length > 1).join(', ')}</p>
            </div>
            <p>{email}</p>
            <p>{phoneNumber}</p>
          </div>
        </div>
        <AnimateHeight
          duration={500}
          height={active ? 'auto' : 0}
        >
          <div className="body">
            <this.inputWithChecking title={i18n('checkout', 'checkout_fullname')} name={'fullName'} errorText={i18n('checkout', 'checkout_name_is_required')}/>

            <div className="select__wrap select__wrap--short not-mob success">
              <label>{i18n('checkout', 'checkout_country')}</label>
              <CustomSelector
                items={store.countries.map(country => ({
                  key: country.country_code,
                  title: country.country_name,
                }))}
                keyActive={store.cart.country_code}
                onSelect={item => store.cart.country_code = item.key}
              />
              <div className="error">
                {i18n('checkout', 'checkout_country_is_required')}
              </div>
            </div>
            <this.inputWithChecking title={i18n('checkout', 'checkout_address')} name={'address'} errorText={i18n('checkout', 'checkout_address_is_required')}/>
            <div className={`select__wrap select__wrap-2 not-mob ${zipState}`}>
              <div className="wrapper">
                <div className="wrap">
                  <label>{i18n('checkout', 'checkout_zip')}</label>
                  <input type="text" name={'zip'} pattern={'[0-9]*'} maxLength={6} onChange={this.handleZipChange} value={this.state.zip}/>
                </div>
                <div className="wrap state">
                  <label><div dangerouslySetInnerHTML={{ __html: i18n('checkout', 'checkout_state') }} /></label>
                  <input type="text" name={'state'} value={this.state.state} onChange={this.handleInputChange}/>
                </div>
              </div>
              <div className="error">
                {i18n('checkout', 'checkout_zip_state_required')}
              </div>
            </div>
            <this.inputWithChecking title={i18n('checkout', 'checkout_city')} name={'city'} errorText={i18n('checkout', 'checkout_city_is_required')}/>
            {this.renderChooseContact()}
          </div>
        </AnimateHeight>
        <SecureCheckoutEstimatedTotal/>
      </div>
    );
  }
}

ShippingAddressForm.propTypes = {
  stateChangeCallback: PropTypes.func,
};

export default inject('store')(observer(ShippingAddressForm));

