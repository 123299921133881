import React, {Component} from 'react';
import {addItemToCart} from "../lib/cart";
import {generateToken} from "../lib";
import appStore from "../store/app";

const withHandleAddItem = StoreComponent => {
  return class DecoratedComponent extends Component {

    handleAddItem = async (code, count) => {
      appStore.cartAddButtons[code] = true;
      await addItemToCart(code, count);
      if (appStore.notifyPopups.length > 2)
        appStore.notifyPopups.shift();
      appStore.notifyPopups.push({
        uid: generateToken(16),
        item: appStore.storeItems[code],
        count,
      });
      appStore.cartAddButtons[code] = false;
    };

    render() {
      return <StoreComponent {...this.props} handleAddItem={this.handleAddItem}/>
    }
  }
};

export default withHandleAddItem;

