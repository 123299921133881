import React, {Component} from 'react';
import { inject, observer } from "mobx-react";
import ShippingCostPopup from "./StorePopup/ShippingCostPopup";
import numberFormatter from 'number-formatter';

class DeliveryInfoBlock extends Component {

  render() {
    const { cart, countries, cheapestDelivery } = this.props.store;
    const selectedCountry = countries.find(item => item.country_code === cart.country_code);
    return (
      <p>
        Estimated shipping cost to
        <span
          className="open-popup-link"
          onClick={() => this.props.store.storePopup = <ShippingCostPopup/>}
        > <span>{selectedCountry.country_name}</span>
                                        </span> from ₣&nbsp;{numberFormatter('# ##0.00', cheapestDelivery.delivery_price)} ({cheapestDelivery.delivery_description})
      </p>
    );
  }
}

export default inject('store')(observer(DeliveryInfoBlock));
