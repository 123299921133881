import React, {Component} from 'react';
import { inject, observer } from "mobx-react/custom";
import PageContainer from "../components/PageContainer";
import Header from "../components/Header";
import NavLine from "../components/Header/NavLine";
import Footer from "../components/Footer";
import PhoneStoreBlock from "../components/Store/PhoneStoreBlock";
import MapNotifications from "../components/Store/MapNotifications";

class StorePhone extends Component {

  componentDidMount() {
    const { store } = this.props;
    store.storePopup = null;
    store.notifyPopups = [];
  }

  render() {
    const {
      store: {
        storePopup,
        i18n,
      },
    } = this.props;

    return (
      <PageContainer>
        <Header/>
        <div className="container">
          <MapNotifications/>
          <div className="wrap-bg">

            <NavLine/>

            <PhoneStoreBlock/>

            <div className="features">
              <p>{i18n('buyphone', 'buyphone_a_combination')}</p>
              <div className="features__block">
                <h2>{i18n('buyphone', 'buyphone_features')}</h2>
                <ul>
                  <li>
                    <h3>{i18n('buyphone', 'buyphone_panic_pin')}</h3>
                    <p>{i18n('buyphone', 'buyphone_wipe_all')}</p>
                  </li>
                  <li>
                    <h3>{i18n('buyphone', 'buyphone_encrypted_notes')}</h3>
                    <p>{i18n('buyphone', 'buyphone_you_can_store')}</p>
                  </li>
                  <li>
                    <h3>Pre-installed anonymous messenger</h3>
                    <p>{i18n('buyphone', 'buyphone_the_phone')}</p>
                  </li>
                  <li>
                    <h3>{i18n('buyphone', 'buyphone_firewall')}</h3>
                    <p>{i18n('buyphone', 'buyphone_we_provide')}</p>
                  </li>
                </ul>
                <div className="features__in-box">
                  <h2>{i18n('buyphone', 'buyphone_whats_in_the_box')}</h2>
                  <ul>
                    <li>
                      <p>{i18n('buyphone', 'buyphone_pixel_3a')}</p>
                    </li>
                    <li>
                      <p>{i18n('buyphone', 'buyphone_usb_c')}</p>
                    </li>
                    <li>
                      <p>{i18n('buyphone', 'buyphone_sim_clip')}</p>
                    </li>
                    <li>
                      <p>{i18n('buyphone', 'buyphone_usb_c_18w')}</p>
                    </li>
                    <li>
                      <p>Quick Start Guide</p>
                    </li>
                    <li>
                      <p>Messenger Guide</p>
                    </li>
                  </ul>
                </div>
              </div>

            </div>

          </div>
        </div>
        <Footer/>
        {storePopup}

      </PageContainer>
    );
  }
}

export default inject('store')(observer(StorePhone));
