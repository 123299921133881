import React, {Component} from 'react';
import { inject, observer } from "mobx-react";
import PageContainer from "../components/PageContainer";
import Header from "../components/Header";
import NavLine from "../components/Header/NavLine";
import Footer from "../components/Footer";
import OrderTracking from "../components/Order/OrderTracking";

class Payment extends Component {
  render() {
    return (
      <PageContainer>
        <Header/>
        <div className="container">
          <div className="wrap-bg">
            <NavLine/>
          </div>
          <OrderTracking/>
        </div>
        <Footer withoutTop={true}/>
      </PageContainer>
    );
  }
}

export default inject('store')(observer(Payment));
