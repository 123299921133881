const scrollToTop = duration => {
  const currentX = window.pageXOffset || document.documentElement.scrollLeft;
  let currentY = window.pageYOffset || document.documentElement.scrollTop;
  const step = currentY / duration;
  let interval = setInterval(() => {
    window.scrollTo(currentX, currentY -= step);
    if (currentY <= step) {
      window.scrollTo(currentX, 0);
      clearInterval(interval);
    }
  }, 1);
};

export {
  scrollToTop,
};
