import React from 'react';
import { inject, observer } from 'mobx-react';
import PageContainer from "../components/PageContainer";
import Header from "../components/Header";
import NavLine from "../components/Header/NavLine";
import Footer from "../components/Footer";

const Subscription = ({ store: { i18n }}) => {
  return (
    <PageContainer>
      <Header/>
      <div className="container">
        <div className="wrap-bg">
          <NavLine/>
        </div>
        <div className="subscription">
          <h3><div dangerouslySetInnerHTML={{ __html: i18n('subscription', 'subscription_all_available') }} /></h3>
        </div>
      </div>
      <Footer/>
    </PageContainer>
  );
};

export default inject('store')(observer(Subscription));
