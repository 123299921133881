import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

class Tabs extends Component {
  state = {
    active: 0,
  };

  componentDidMount() {
    const { active } = this.props;
    active !== undefined && this.setState({ active });
  }

  render() {
    const { tabs } = this.props;
    const { active } = this.state;
    return (
      <Fragment>
        <div className="tab">
          {tabs.map(({header}, key) => (
            <span
              className={key === active ? 'active' : ''}
              onClick={() => this.setState({ active: key })}
              key={key}
            >
                            {header}
                        </span>
          ))}
        </div>
        {tabs.map((tab, key) => {
          let style = {};
          if (key !== active)
            style = { display: 'none' };
          return (
            <div key={key} style={style}>
              {tab.content}
            </div>
          );
        })}
        {/*{tabs[active].content}*/}
      </Fragment>
    );
  }
}

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  active: PropTypes.number,
};

export default Tabs;
