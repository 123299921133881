import React from "react";
import PageContainer from "../components/PageContainer";
import Header from "../components/Header";
import NavLine from "../components/Header/NavLine";
import Footer from "../components/Footer";
import Tabs from "../components/Tabs";
import { Link } from 'react-router-dom';
import { inject, observer } from "mobx-react";
import { withRouter } from 'react-router-dom';

const Terms = ({ history: { location } }) => (
  <PageContainer>
    <Header/>
    <div className="container">
      <div className="wrap-bg">

        <NavLine/>

      </div>
      <div className="rules-block">
        <Tabs
          active={location.pathname.indexOf('privacy') > -1 ? 1 : 0}
          tabs={[
            {
              header: 'Terms of use',
              content:
                <div className="rules active">
                  <div className="rules__title">
                    Terms of use
                  </div>
                  <span>Last update: October 19, 2019.</span>
                  <p>
                    These Terms of Service (“Terms”) apply to your access to and use of the www.boqpod.ch, Boqpod
                    mobile application and other online products and services (collectively, the “Services”) provided by
                    Boqpod GmBh (“Company” or “we”). By clicking “I Accept” or by using our Services, you agree
                    to these Terms.</p>
                  <p>
                    We may supply different or additional terms in relation to some of our other Services or products,
                    and those different or additional terms become part of your agreement with us if you use those
                    Services. If there is a conflict between these Terms and the additional terms, the additional terms
                    will control and clarify for that conflict.</p>
                  <p>
                    We may make changes to these Terms. If we make changes, we will provide you with notice of such
                    changes, such as by sending an email, providing a notice through our Services or updating them at
                    homepage. Unless we say otherwise in our notice, the amended Terms will be effective
                    immediately, and your continued use of our Services after we provide such notice will confirm your
                    acceptance of the changes.</p>
                  <p>
                    If you have any questions about these Terms or our Services, please contact us at&nbsp;
                    <a href="mailto:privacy@boqpod.ch">privacy@boqpod.ch</a>.
                  </p>
                  <ol>
                    <li>
                      <h3>Privacy</h3>
                      <p><Link to={'/privacy'}>Privacy Statement.</Link></p>
                    </li>
                    <li>
                      <h3>ELIGIBILITY</h3>
                      <p>
                        You must be at least 13 years of age to use our Services. If you are under 18 years of age (or the age
                        of legal majority where you live), you may use our Services only under the supervision of a parent
                        or legal guardian who agrees to be bound by these Terms. If you are a parent or legal guardian of a
                        user under the age of 18 (or the age of legal majority), you agree to be fully responsible for the acts
                        or omissions of such user in relation to our Services. We do not collect personal information from
                        users. If you use our Services on behalf of another person or entity, (a) all references to “you”
                        throughout these Terms will include that person or entity, (b) you represent that you are authorized
                        to accept these Terms on that person’s or entity’s behalf, and (c) in the event you or the person or
                        entity violates these Terms, the person or entity agrees to be responsible to us.
                      </p>
                    </li>
                    <li>
                      <h3>FEES</h3>
                      <p>
                        You are solely responsible for hardware and software necessary to connect to the Services,
                        including, but not limited to, a mobile device that is suitable to connect with and use the Services.</p>
                      <p>
                        You are responsible for any charges and fees, including internet connection or mobile carrier
                        charges, that you incur when accessing the Services.
                      </p>
                    </li>
                    <li>
                      <h3>USER ACCOUNTS AND ACCOUNT SECURITY</h3>
                      <p>
                        You do not need to register for an account to access some or all of our Services. By using our
                        messenger you will get your own conection ID, 256 bit number. This number will be your identifier
                        within our Services network («User», «Account»). You will also have the option of uploading a
                        profile picture, username and other information. If you may want it.</p>
                      <p>
                        You are responsible for all activities that that occur in connection with your Account. You also must
                        maintain the security of your Account. There is no real way to get access to your Account without
                        your permission. You agree that you will secure your Account, password and Seed phrase from
                        anyone. and promptly notify us if you discover or suspect that someone has accessed your Account
                        without your permission. We reserve but do not grant the right to reclaim usernames, including on
                        behalf of businesses or individuals that hold legal claim, including trademark rights, in those
                        usernames.</p>
                      <p>
                        As part of our Services, User may communicate not only with other User but as well with group of
                        Users with whom they are united by group messaging functionality.
                      </p>
                    </li>
                    <li>
                      <h3>User Content</h3>
                      <p>
                        The Services consist of interactive features and areas that allow User to create, transmit, store and
                        share content, including, but not limited to text, pictures, audio, videos, information or other
                        materials (collectively, «User Content», «Messages»). No one have access to your User Content
                        without your allowness. When you send, post or otherwise share User Content, you understand that
                        it and any associated information may get visible to others. You acknowledge that User Content
                        may contain information that has been designated as confidential, secret, valued and you agree not
                        to disclose such information without care or consent.</p>
                      <p>
                        The Services eliminates leakages and unauthorised access. You grant Services and its Users a nonexclusive, royalty-free, worldwide, fully paid, and sublicenseable license to transmit, use,
                        reproduce, modify, adapt, publish, translate, create derivative works from, distribute, perform and
                        display your Messages and any name, username or likeness provided in connection with your User
                        Content in all media formats and channels now known or later developed without compensation to
                        you. You represent and warrant that your User Content, and use of such content by others will not
                        violate any rights of or cause injury to any person or entity. You agree that you are solely
                        responsible for your User Content and that Company is not source of and not responsible or liable
                        for any User Content.
                      </p>
                    </li>
                    <li>
                      <h3>COMMUNITY GUIDELINES; PROHIBITED CONDUCT, CONTENT</h3>
                      <p>
                        The Boqpod mobile application was created for people to communicate willingly with each other
                        while not wanting to leave a trace of the conversation. Although we can not , we are not obliged to
                        and we do not monitor, access to, use, review, verify or edit any User Content. The Company
                        reserve the rights to setup guidelines of secure communications, notify of prohibitons, remove or
                        disable access at any time at our sole discretion.
                      </p>
                    </li>
                    <li>
                      <h3>OWNERSHIP; LIMITED LICENSE</h3>
                      <p>
                        The Services, including the text, graphics, images, photographs, videos, illustrations and other
                        content contained therein, are owned by Company or our licensors and are protected under laws.
                        Except as explicitly stated in these Terms, all rights in and to the Services are reserved by us or our
                        licensors. Subject to your compliance with these Terms, you are hereby granted a limited,
                        nonexclusive, nontransferable, non-sublicenseable, revocable license to access and use our Services
                        for your own personal, noncommercial use. Any use of the Services other than as specifically
                        authorized herein, without our prior written permission, is strictly prohibited and will terminate the
                        license granted herein and violate our intellectual property rights.
                      </p>
                    </li>
                    <li>
                      <h3>TRADEMARKS</h3>
                      <p>
                        Our product or service names, our catchwords and the look and feel of the Services are trademarks
                        of Company and may not be copied, imitated or used, in whole or in part, without our prior written
                        permission. All other trademarks, registered trademarks, product names and company names or
                        logos mentioned on the Services are the property of their respective owners. Reference to any
                        products, services, processes, third parties or other information by trade name, trademark,
                        manufacturer, supplier or otherwise does not constitute or imply endorsement, sponsorship or
                        recommendation by us.
                      </p>
                    </li>
                    <li>
                      <h3>feedback</h3>
                      <p>
                        You may voluntarily post, submit or otherwise communicate to us any questions, comments,
                        suggestions, ideas, original or creative materials or other information about Company or our
                        Services (collectively, “Feedback”). You understand that we may use such Feedback for any
                        purpose, commercial or otherwise, without acknowledgement or compensation to you, including,
                        without limitation, to develop, copy, publish, or improve the Feedback in Company’s sole
                        discretion. You understand that Company may treat Feedback as nonconfidential.
                      </p>
                    </li>
                    <li>
                      <h3>Third-party Services</h3>
                      <p>
                        The Apps and the Service may be incorporated into, and may incorporate, technology, software and
                        services owned and controlled by third-parties. Use of such third-party software or services is
                        subject to the terms and conditions of the applicable third-party license agreements (including,
                        without limitation, terms of use and terms of service agreements posted on third-party websites and
                        privacy policies posted on third-party websites), and you agree to look solely to the applicable thirdparty and not to the Company to enforce any of your rights in relation thereto.
                      </p>
                    </li>
                    <li>
                      <h3>Compliance with Laws</h3>
                      <p>
                        You agree to comply with all applicable laws regarding your use of the Services. You further agree
                        that information provided by you is truthful and accurate to the best of your knowledge.
                      </p>
                    </li>
                    <li>
                      <h3>TRANSFER AND PROCESSING DATA</h3>
                      <p>
                        The Services are hosted in Switzerland. In order for us to provide our Services, you agree that we
                        may process, transfer and store information about you that you gave us in the other countries, where
                        you may not have the same rights and protections as you do under local law.
                      </p>
                    </li>
                    <li>
                      <h3>Indemnification</h3>
                      <p>
                        To the fullest extent permitted by applicable law, you agree to indemnify, defend and hold Company
                        and our subsidiaries and affiliates, and each of our respective officers, directors, agents, partners
                        and employees, harmless from and against any liability, loss, claim, demand, damage, expense or
                        cost, including reasonable attorneys’ fees, arising out of or related to your (a) violation of these
                        Terms or (b) access to or use of the Services.</p>
                      <p>
                        You agree that the provisions in this paragraph will survive any termination of your Account(s) or
                        of the Services.
                      </p>
                    </li>
                    <li>
                      <h3>Disclaimers</h3>
                      <p>
                          You expressly agree that your use of the Services is at your sole risk, and our Services and any
                          content therein are provided “as is” and “as available” without warranties of any kind, either
                          express or implied, including, but not limited to, implied warranties of non-infringement, title,
                          merchantability or fitness for a particular purpose (except only to the extent prohibited under
                          applicable law with any legally required warranty period to the shorter of thirty days from first
                          use or the minimum period required).</p>
                      <p>
                        <b>
                          In addition, neither Company nor its affiliates or subsidiaries, or any of their respective directors,
                          employees, agents, attorneys, third-party content providers, distributors, licensees or licensors
                          (collectively, “Company Parties”) represent or warrant that the Services will be accurate,
                          complete, reliable, current, uninterrupted or error-free. While Company attempts to make your
                          use of our Services and any content therein safe, we cannot and do not represent or warrant that
                          our Services or servers are free of viruses or other harmful components. You assume the entire
                          risk as to the quality and performance of the Services.</b>
                      </p>
                    </li>
                    <li>
                      <h3>Limitation of Liability</h3>
                      <p>
                        To the fullest extent permitted by applicable law, Company and the other Company Parties will
                        not be liable or responsible to you for any indirect, incidental, consequential (including damages
                        from loss of business, lost profits, litigation, or the like), special, exemplary, punitive, or other
                        damages, under any legal theory, whether based in contract, tort, negligence, warranty or
                        otherwise, even if Company or the other Company Parties have been advised of the possibility of
                        such damages. To the fullest extent permissible under applicable law, your sole remedy for
                        dissatisfaction with the Services or any content therein is to cease all use of the Services.</p>
                      <p>
                        The limitations set forth in this Section 15 will not limit or exclude liability for the gross
                        negligence, fraud or intentional misconduct of Company or the other Company Parties or for
                        any other matters in which liability cannot be excluded or limited under applicable law.
                        Additionally, some jurisdictions do not allow the exclusion or limitation of certain warranties or
                        the limitation or exclusion of liability for certain types of damages, so the above limitations or
                        exclusions may not apply to you. In particular, nothing in these Terms shall affect the statutory
                        rights of any consumer or exclude or restrict any liability for death or personal injury arising
                        from any negligence or fraud of Company or any Company Party.
                      </p>
                    </li>
                    <li>
                      <h3>Dispute Resolution: GOVERNING LAW</h3>
                      <p>
                        If you are using the Service in any country within the European Union or otherwise outside of the
                        United States the following applies: The laws applicable to the interpretation of these Terms of
                        Service shall be the laws of Switzerland, without regard to any conflicts of law provisions. The
                        application of the United Nations Convention on Contracts for the International Sale of Goods is
                        expressly excluded. Except as provided in Section 19 below (and claims proceeding in any small
                        claims court), you irrevocably submit to the personal and exclusive jurisdiction of the courts located
                      </p>
                    </li>
                    <li>
                      <h3>General</h3>
                      <ol>
                        <li>No Partnership. You agree that no joint venture, partnership, employment, or agency
                        relationship exists between you and the Company as a result of this ToU or your use of the Apps,
                          the Site and/or the Service.</li>
                        <li>Assignment. The Company may assign its rights under this ToU to any person or entity without
                        your consent. The rights granted to you under this ToU may not be assigned without the Company’s
                          prior written consent, and any attempted unauthorized assignment by you shall be null and void.</li>
                        <li>Severability. If any part of this ToU is determined to be invalid or unenforceable, then that
                          portion shall be severed, and the remainder of the ToU shall be given full force and effect.</li>
                        <li>No Waiver. Our failure to enforce any provision of this ToU shall in no way be construed to be
                        a present or future waiver of such provision, nor in any way affect our right to enforce the same
                        provision at a later time. An express waiver by the Company of any provision, condition or
                        requirement of this ToU shall not be understood as a waiver of your obligation to comply with the
                          same provision, condition or requirement at a later time.</li>
                        <li>
                          <p>updating, modifying and terminating our services. We may require that you accept updates to
                        the Services you have installed on or accessed through your computer or mobile device in order to
                        continue using the Services. You acknowledge and agree that we may update the Services with or
                        without notifying you. In addition, you may need to update third-party software from time to time
                        in order to receive the Services you have installed on or accessed through your computer or mobile
                            device.</p>
                          <p>
                        We reserve the right to modify our Services or to suspend or stop providing all or portions of our
                        Services at any time. You also have the right to stop using our Services at any time. We are not
                        responsible for any loss or harm related to your inability to access or use our Services.
                          </p>
                        </li>
                        <li>Notices. All notices given by you or required under this ToU shall be in writing and addressed
                          to: Boqpod GmbH, Poststrasse 14, 6300 Zug, Switzerland; <a href="mailto:privacy@boqpod.ch">privacy@boqpod.ch</a></li>
                      </ol>
                    </li>
                  </ol>
                </div>
            },
            {
              header: 'Privacy policy',
              content:
                <div className="rules active">
                  <div className="rules__title">
                    Privacy policy
                  </div>
                  <span>Last update: July 29, 2019.</span>
                  <p>
                    boqpod GmbH ("us", "we", or "our") operates the www.boqpod.ch website and the boqpod mobile
                    application (hereinafter referred to as the "Service").
                    This page informs you of our policies regarding the collection, use and disclosure of personal data
                    when you use our Service and the choices you have associated with that data.
                    We use your data to provide and improve the Service only.
                    By using the Service, you agree to the collection and use of information in accordance with this
                    policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have
                    the same meanings as in our Terms and Conditions, at www.boqpod.ch
                  </p>

                  <h3>Information Collection and Use</h3>
                  <p>
                    boqpod is a zero log Service. We do not record or retain any data when you use it.<br/>
                    This means:
                  </p>
                  <ul>
                    <li>We do not store or save a user's traffic or user`s profiles</li>
                    <li>We do not log, control, access or assist the content of communications</li>
                    <li>We are neutral. We can not and we do not control, edit or set any restrictions to the textual or
                      media content of communications.</li>
                  </ul>
                  <p>
                    While using our Services, we may ask you to provide us with certain Personal Data. Personal Data
                    can include your email address and/or phone number, payment information, physical address.
                    We uses the collected Personal Data only to provide customer support of our Services.<br/>
                    This means:
                  </p>
                  <ul>
                    <li>To notify you about changes to our Service</li>
                    <li>To allow you to participate in new features of our Service when you choose to do so</li>
                    <li>To gather analysis or valuable information so that we can improve our Service</li>
                    <li>To share information of the usage of our Service</li>
                    <li>To detect, prevent and address technical issues</li>
                  </ul>
                  <h3>Legal Basis for Processing Personal Data
                    under the General Data Protection Regulation
                    (GDPR)</h3>
                  <p>
                    If you are from the European Economic Area (EEA), legal basis for collecting and using the
                    personal information described in this Privacy Policy depends on the Personal Data we collect and
                    the specific context in which we collect it.<br/>
                    We may process your Personal Data because:
                  </p>
                  <ul>
                    <li>We need to perform a contract with you</li>
                    <li>You have given us permission to do so</li>
                    <li>To comply with the law</li>
                  </ul>

                  <h3>Retention, Transfer and Security of Data</h3>
                  <p>
                    We will retain your Personal Data only for as long as is necessary for the purposes set out in this
                    Privacy Policy. We may retain your Personal Data to the extent necessary to comply with our legal
                    obligations.</p>
                  <p>
                    Your information, including Personal Data, may be transferred to — and maintained on —
                    computers located outside of your state, province, country or other governmental jurisdiction where
                    the data protection laws may differ from those of your jurisdiction.</p>
                  <p>
                    We will take all the steps reasonably necessary to ensure that your data is treated securely and in
                    accordance with this Privacy Policy and no transfer of your Personal Data will take place to an
                    organization or a country unless there are adequate controls in place including the security of your
                    data and other personal information. The security of your data is important to us but remember that
                    no method of transmission over the Internet or method of electronic storage is 100% secure. While
                    we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee
                    its absolute security.</p>
                  <p>
                    Your consent to this Privacy Policy followed by your submission of such information represents
                    your agreement to that.</p>

                  <h3>Your Data Protection Rights under the
                    General Data Protection Regulation (GDPR)</h3>
                  <p>
                    If you are a resident of the European Economic Area (EEA), you have certain data protection rights.
                    We aims to take reasonable steps to allow you to correct, amend, delete or limit the use of your
                    Personal Data.</p>
                  <p>
                    If you wish to be informed about what Personal Data we hold about you and if you want it to be
                    removed from our systems, please contact us.</p>
                  <p>
                    In certain circumstances, you have the following data protection rights:</p>
                  <ol>
                    <li>The right to access, update or delete the information we have on you. Whenever made possible,
                      you can access, update or request deletion of your Personal Data directly within your account
                      settings section. If you are unable to perform these actions yourself, please contact us to assist you.</li>
                    <li>The right of rectification. You have the right to have your information rectified if that information
                      is inaccurate or incomplete.</li>
                    <li>The right to object. You have the right to object to our processing of your Personal Data.</li>
                    <li>The right of restriction. You have the right to request that we restrict the processing of your
                      personal information.</li>
                    <li>The right to data portability. You have the right to be provided with a copy of the information we
                      have on you in a structured, machine-readable and commonly used format.</li>
                    <li>The right to withdraw consent. You also have the right to withdraw your consent at any time where
                      bq relied on your consent to process your personal information.</li>
                    <li>Please note that we may ask you to verify your identity before responding to such requests.
                      If you wish to be removed from our systems, please contact us at <a href="mailto:support@boqpod.ch">support@boqpod.ch</a></li>
                  </ol>
                  <h3>Children's Privacy</h3>
                  <p>
                    Our Service does not address anyone under the age of 13 ("Children").</p>
                  <p>
                    We do not knowingly collect personally identifiable information from anyone under the age of 18.
                    If you are a parent or guardian and you are aware that your Child has provided us with Personal
                    Data, please contact us. If we become aware that we have collected Personal Data from children
                    without verification of parental consent, we take steps to remove that information from our servers.</p>
                  <h3>Changes to This Privacy Policy</h3>
                  <p>
                    We may update our Privacy Policy from time to time. We will notify you of any changes by posting
                    the new Privacy Policy on this page.</p>
                  <p>
                    We will let you know via email and/or a prominent notice on our Service, prior to the change
                    becoming effective and update the "effective date" at the top of this Privacy Policy.</p>
                  <p>
                    You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy
                    Policy are effective when they are posted on this page.</p>
                  <h3>Contact Us</h3>
                  <p>
                    If you have any questions about this Privacy Policy, please contact us at&nbsp;<a href="mailto:privacy@boqpod.ch">privacy@boqpod.ch</a>
                  </p>
                </div>
            },
          ]}/>
      </div>
    </div>
    <Footer/>
  </PageContainer>
);

export default withRouter(inject('store')(observer(Terms)));
