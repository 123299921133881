import React from 'react';
import { DownloadApk, numberPics } from '../../constants/images';
import InstructionPlayer from './ImagePlayer';
import { observer, inject } from 'mobx-react';

const MessengerHowToInstall = props => {
  const { store: { shaKey, i18n } } = props;
  return (
    <div
      className="description-block description-block--messenger description-block--messenger-4"
    >
      <InstructionPlayer />
      <div className="wrap">
        <h2>{i18n('messenger', 'messenger_how_to_install')}</h2>
        <div className="jumbotron">
          <a href={`https://www.boqpod.ch/download/boqpod.apk?rnd=${Math.random()}`}><img src={DownloadApk} alt="" /></a>
          <p>.apk checksum (SHA256):</p>
          <p className="wordbreak">{shaKey}</p>
        </div>
        <div className="wrap-img">
          <div className="img-block">
            <img src={numberPics[0]} alt="" />
          </div>
          <p>{i18n('messenger', 'messenger_open_the_security')}</p>
        </div>
        <div className="wrap-img">
          <div className="img-block">
            <img src={numberPics[1]} alt="" />
          </div>
          <p>Copy&nbsp;
            <em
              onClick={() => window.open(`https://www.boqpod.ch/download/boqpod.apk?rnd=${Math.random()}`, '_blank')}
            >.apk file</em> to your device or download it to your device using mobile web
            browser
          </p>
        </div>
        <div className="wrap-img">
          <div className="img-block">
            <img src={numberPics[2]} alt="" />
          </div>
          <p>{i18n('messenger', 'messenger_locate_file')}</p>
        </div>
      </div>
    </div>
  );
};

export default inject('store')(observer(MessengerHowToInstall));
