import React from 'react';
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";

const CartBlock = props => {
  const { cart, isCartNotEmpty } = props.store;

  return (
    isCartNotEmpty
      ?
      <div className="header__link">
        <Link to={`/cart/${cart.cart_token}`}>
          <div className="header__cart active">
          </div>
        </Link>
      </div>
      :
      <div className="header__link">
        <div className="header__cart">
        </div>
      </div>
  );
};

export default inject('store')(observer(CartBlock));
