import React from 'react';
import LoaderCircle from "./LoaderCircle";

const loaderStyle = {
  position: 'fixed',
  left: 0,
  top: 0,
  width: '100%',
  height: '100vh',
  // paddingTop: '30%',
  backgroundColor: 'rgba(0, 0, 0, .9)',
  textAlign: 'center',
  zIndex: 100,
};



const Loader = () => {
  return (
    <div style={loaderStyle}>
            <span className="loader loader--center">
                <LoaderCircle/>
            </span>
    </div>
  );
};

export default Loader;
