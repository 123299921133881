import React, {Component, Fragment} from 'react';
import {inject, observer} from "mobx-react";
import Loader from "./Loader";

class PageContainer extends Component {
  componentDidMount() {
    this.props.store.mobileMenuOpen = false;
  }

  render() {
    const { isLoading, isSiteLoaded } = this.props.store;
    return (
      <Fragment>
        {isLoading && <Loader/>}
        {isSiteLoaded && this.props.children}
      </Fragment>
    );
  }
}

export default inject('store')(observer(PageContainer));
