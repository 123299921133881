import React, {Component} from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';

class Accordion extends Component {
  state = {
    active: -1,
  };

  componentDidMount() {
    const { active } = this.props;
    active !== undefined && this.setState({ active });
  }

  handleClick = active => {
    if (active === this.state.active)
      active = -1;
    this.setState({ active });
  };

  mapTitle = () => {
    if (this.props.title === undefined) return null;
    return (
      <div className="accordion-title">
        {this.props.title}
      </div>
    );
  };

  render() {
    const { items } = this.props;
    const { active } = this.state;
    return (
      <div className="accordion active">
        {this.mapTitle()}
        {items.map((item, key) => {
          return (
            <div className={`item ${key === active && 'active'}`} key={key}>
              <div className="head" onClick={() => this.handleClick(key)}>{item.header}</div>
              <AnimateHeight
                duration={500}
                height={key === active ? 'auto' : 0}
              >
                <div className="body">{item.body}</div>
              </AnimateHeight>
            </div>
          );
        })}
      </div>
    );
  }
}

Accordion.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array.isRequired,
  active: PropTypes.number,
};

export default Accordion;
