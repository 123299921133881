import { observable, decorate, computed } from "mobx";
import {
  fetch_cart, fetch_content,
  fetch_countries,
  fetch_cryptos,
  fetch_deliveries,
  fetch_items,
  fetch_languages, get_client_ip, get_country_by_ip, get_country_code, get_sha_key
} from "../api";
import {CONTACT_BY_BOQPOD_ID, GSMBOX_DB_CODE, LOCAL_STORAGE_KEY, PHONE_DB_CODE} from "../constants";
import {loadBigImages} from "../constants/images";

const __lang = localStorage.getItem('language');

class AppStore {
  sticky = false;
  _language = 'en';
  isLoading = false;
  _isSiteLoaded = false;
  languages = [];
  deliveries = [];
  countries = [];
  cryptos = [];
  apiFail = false;
  delivery_id = undefined;
  // bitcoinRates = {};
  btcInterval = null;
  shaKey = '';
  siteContent = {};

  userCountryCode = 'CH';

  mobileMenuOpen = false;

  get mobileMenuClass() {
    return this.mobileMenuOpen ? 'active' : '';
  }

  cart = {
    cart_token: '',
    items: [],
    country_code: '',
    delivery_id: '',
  };

  checkout = {
    payment_type: 'BTC',
    contact_by: CONTACT_BY_BOQPOD_ID,
  };

  storeItems = {};

  cartAddButtons = {
    [PHONE_DB_CODE]: false,
    [GSMBOX_DB_CODE]: false,
  };

  storePopup = null;
  notifyPopups = [];

  storageData = {};

  shippingForm = {};
  shippingFormValid = false;

  get isCartNotEmpty() {
    return this.cart.items.length > 0;
  }

  get cartTotal() {
    return this.cart.items.length > 0 ?
      this.cart.items.map(item => this.storeItems[item.item_code].item_price * item.count)
        .reduce((a, b) => a + b) : 0;
  }

  get estimatedTotal() {
    const delivery = this.deliveries.find(item => item.id === this.cart.delivery_id);
    return [this.cartTotal, delivery !== undefined ? delivery.delivery_price : 0]
      .reduce((a, b) => a + b);
  }

  get cartItemsCount() {
    return this.cart.items.map(item => item.count).reduce((a, b) => a + b);
  }

  get isSiteLoaded() {
    // return Object.keys(this.storeItems).length > 0;
    return this._isSiteLoaded;
  }

  get cheapestDelivery() {
    return this.deliveries.sort((a, b) => a.delivery_price - b.delivery_price)[0];
  };

  set language(lang) {
    this._language = lang;
  }

  get language() {
    return this._language;
  }

  clearCart = () => {
    this.cart = {
      cart_token: '',
      items: [],
    };
    this.cart.country_code = this.userCountryCode;
    this.cart.delivery_id = this.deliveries[0].id;
  };

  i18n = (page, key) => {
    return this.siteContent[page][key] || key;
  };

  changeLanguage = async lang => {
    this.isLoading = true;
    this.language = lang;
    try {
      const resContent = await fetch_content(lang);
      this.siteContent = JSON.parse(resContent.data.data.content_data);
      localStorage.setItem('language', lang);
    }
    catch (e) {
      console.error(e);
    }
    this.isLoading = false;
  };

  initApp = async () => {
    this.isLoading = true;
    this._isSiteLoaded = false;

    try {
      const res = await Promise.all([
        fetch_languages(),
        fetch_deliveries(),
        fetch_countries(),
        fetch_items(),
        fetch_cryptos(),
      ]);
      await loadBigImages();

      this.languages = res[0].data.data.items;
      this.deliveries = res[1].data.data.items;
      this.countries = res[2].data.data.items;
      this.cryptos = res[4].data.data.items;

      try {
        // const resIP = await get_client_ip();
        // const ip = resIP.data.data.ip;
        const whoIs = await get_country_code();
        this.userCountryCode = whoIs.data.data.countryCode;
        const resSHA = await get_sha_key();
        this.shaKey = resSHA.data.data.sha_key;
      }
      catch (e) {
        this.userCountryCode = 'CH'
      }
      await this.changeLanguage(__lang || 'en');

      this.checkout.payment_type = this.cryptos[0].currency_code;

      this.btcInterval = setInterval(async () => {
        try {
          const res = await fetch_cryptos();
          this.cryptos = res.data.data.items;
        }
        catch (e) {
          console.error(e);
        }
      }, 1000 * 60 * 15);

      this.cart.country_code = this.userCountryCode;
      this.cart.delivery_id = this.deliveries[0].id;

      let storeItems = {};
      res[3].data.data.items.map(item => {
        storeItems = {
          ...storeItems,
          [item.item_code]: item,
        }
      });
      this.storeItems = storeItems;
      let storageData = localStorage.getItem(LOCAL_STORAGE_KEY);
      if (storageData !== null) {
        try {
          const storageCart = JSON.parse(storageData);
          try {
            const res = await fetch_cart(storageCart.cart_token);
            this.cart = res.data.data;
          }
          catch (e) {
            localStorage.removeItem(LOCAL_STORAGE_KEY);
          }
        }
        catch (e) {
          console.error(e);
        }
      }
    }
    catch (e) {
      this.apiFail = true;
      console.error(e);
    }
    this.isLoading = false;
    this._isSiteLoaded = true;
  };
}

decorate(AppStore, {
  sticky: observable,
  _language: observable,
  language: computed,
  storePopup: observable,
  isLoading: observable,
  languages: observable,
  _isSiteLoaded: observable,
  isSiteLoaded: computed,
  delivery_id: observable,
  countries: observable,
  selectedCountry: observable,
  cheapestDelivery: computed,
  storeItems: observable,
  cart: observable,
  isCartNotEmpty: computed,
  apiFail: observable,
  notifyPopups: observable,
  cartAddButtons: observable,
  // bitcoinRates: observable,
  estimatedTotal: computed,
  checkout: observable,
  shippingFormValid: observable,
  mobileMenuOpen: observable,
  mobileMenuClass: computed,
  cartItemsCount: computed,
  cryptos: observable,
  shaKey: observable,
  siteContent: observable,
});

const appStore = new AppStore();

export default appStore;
