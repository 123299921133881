import {create_cart, create_order, delete_cart, fetch_cart, remove_cart_item, set_cart_items} from "../api";
import appStore from "../store/app";
import {LOCAL_STORAGE_KEY} from "../constants";

const addItemToCart = async (itemCode, count) => {
  let res = null;
  if (appStore.cart.cart_token === '') {
    try {
      res = await create_cart();
      appStore.cart.cart_token = res.data.data.cart_token;
    }
    catch (e) {
      console.error(e);
      appStore.apiFail = true;
    }
  }

  let cartItem = appStore.cart.items.find(item => item.item_code === itemCode);
  if (cartItem === undefined) {
    cartItem = {
      item_code: itemCode,
      count,
    };
    appStore.cart.items.push(cartItem);
  }
  else {
    cartItem.count += count;
  }
  try {
    res = await set_cart_items(appStore.cart.cart_token, {
      items: appStore.cart.items,
      delivery_id: appStore.cart.delivery_id !== undefined ? appStore.cart.delivery_id : null,
      country_code: appStore.cart.country_code,
    });
    appStore.cart.items = res.data.data.items;
  }
  catch (e) {
    console.error(e);
    appStore.apiFail = true;
  }
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(appStore.cart));
};

const removeItemFromCart = async itemCode => {
  try {
    const res = await remove_cart_item(appStore.cart.cart_token, itemCode);
    appStore.cart.items = res.data.data.items;
  }
  catch (e) {
    console.error(e);
  }
};

const fetchCart = async cartToken => {
  try {
    const res = await fetch_cart(cartToken);
    appStore.cart = res.data.data;
  }
  catch (e) {
    console.error(e);
  }
};

const makeOrder = async cartToken => {
  const { shippingForm, checkout, cart, cryptos } = appStore;
  const currency = cryptos.find(item => item.currency_code === checkout.payment_type);
  const params = {
    payment_type: checkout.payment_type,
    order_amount: Math.round((appStore.estimatedTotal / currency.chf_rate) * 100) / 100,
    user: {
      full_name: shippingForm.fullName,
      country_code: cart.country_code,
      address: shippingForm.address,
      zip: shippingForm.zip,
      state: shippingForm.state || '',
      city: shippingForm.city,
      contact_by: checkout.contact_by,
      phone_number: shippingForm.phoneNumber,
      boqpid_id: shippingForm.boqpodID,
      email: shippingForm.email,
    }
  };
  appStore.isLoading = true;
  try {
    const res = await create_order(appStore.cart.cart_token, params);
    await delete_cart(appStore.cart.cart_token);
    await localStorage.removeItem(LOCAL_STORAGE_KEY);
    return res.data.data;
  }
  catch (e) {
    appStore.apiFail = true;
    console.error(e);
  }
  appStore.isLoading = false;
};

export {
  addItemToCart,
  removeItemFromCart,
  fetchCart,
  makeOrder,
}
