import React, {Component} from 'react';
import { inject, observer } from "mobx-react";
import numberFormatter from "number-formatter";
import {QuestionActivePic, QuestionPic} from "../../../constants/images";

class SecureCheckoutEstimatedTotal extends Component {

  render() {
    const {
      store,
      store: { estimatedTotal, cart, cartTotal },
    } = this.props;
    const delivery = store.deliveries.find(item => item.id === store.cart.delivery_id);
    const selectedCountry = store.countries.find(item => item.country_code === store.cart.country_code);
    return (
      <div className="total-block__wrap total-block__wrap--inside">
        <div className="total-block">
          <div className="total-block__title">Estimated Total</div>
          <ul>
            <li>
              <p>Subtotal ({cart.items.length} items)</p>
              <p className="price">₣{numberFormatter('#,##0.00', cartTotal)}</p>
            </li>
            <li>
              <p>Estimated Shipping to&nbsp;
                <span>{selectedCountry.country_name}</span>

              </p>
              <p className="price">
                {delivery !== undefined
                  ?
                  `₣${delivery.delivery_price}`
                  :
                  `-`
                }
              </p>
            </li>
            {/*<li>*/}
            {/*  <p>Taxes*/}
            {/*    <span className="question-popover-wrap">*/}
            {/*      <img className="question-popover-wrap__img" src={QuestionPic} alt=""/>*/}
            {/*      <img className="question-popover-wrap__img" src={QuestionActivePic} alt=""/>*/}
            {/*      <span className="question-popover">*/}
            {/*        <em>Courier will fly to any country for delivery. Minimum order – 5 items.</em>*/}
            {/*      </span>*/}
            {/*    </span>*/}
            {/*  </p>*/}
            {/*  <p className="price">Not calculated</p>*/}
            {/*</li>*/}
          </ul>
          <div className="total-block__total-cost">
            <p>Estimated Total</p>
            <p className="price">₣{numberFormatter('#,##0.00', estimatedTotal)}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default inject('store')(observer(SecureCheckoutEstimatedTotal));
