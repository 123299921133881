import React from 'react';
import {LoaderPic} from "../../constants/images";
import PropTypes from 'prop-types';

const AddToCartButton = ({ title, loading, onClick, small }) => {
  const handleClick = !loading ? onClick : () => {};
  return (
    !small
      ?
      <span
        className={`btn add-to-cart ${loading ? 'load' : ''}`}
        onClick={handleClick}
      >
            {title}
        <img className="add-to-cart__img" src={LoaderPic} alt=""/>
        </span>
      :
      <span
        className={`add-to-cart add-to-cart--no-bg ${loading ? 'load' : ''}`}
        onClick={handleClick}
      >
                {title}
        <img className="add-to-cart--no-bg__img" src={LoaderPic} alt=""/>
            </span>
  );
};

AddToCartButton.propTypes = {
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  small: PropTypes.bool,
};

export default AddToCartButton;

