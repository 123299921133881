import React, { Component } from 'react';
import { installFrames } from './images';

const PlayButton = ({ onClick }) => (
  <div className="wrap-side__btn" onClick={onClick}>
    <span className="btn">play</span>
  </div>
);

class InstructionPlayer extends Component {
  timeInterval = null;

  state = {
    nFrame: 0,
    isPlayed: false,
  };

  clickHandler = () => {
    this.setState({
      isPlayed: true,
    });
    this.timeInterval = setInterval(() => {
      const { nFrame } = this.state;
      if (nFrame === installFrames.length - 1) {
        clearInterval(this.timeInterval);
        this.setState({
          nFrame: 0,
          isPlayed: false,
        })
      }
      else {
        this.setState({
          nFrame: nFrame + 1,
        });
      }
    }, 1000);
  };

  componentWillUnmount() {
    clearInterval(this.timeInterval);
  }

  render() {
    const { nFrame, isPlayed } = this.state;
    return (
      <div className="wrap-side">
        <h2>How to install apk file on Android device</h2>
        <img src={installFrames[nFrame]} alt=""/>
        {!isPlayed && <PlayButton onClick={this.clickHandler}/>}
      </div>
    );
  }
}

export default InstructionPlayer;
