import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import {fetch_cart} from "../api";

const withCartChecking = OriginalComponent => {
  return class DecoratedComponent extends Component {
    state = {
      cartFail: false,
      cartLoaded: false,
    };

    _loadCart = async () => {
      const { match } = this.props;
      try {
        const res = await fetch_cart(match.params.cart);
        if (res.data.data.items.length < 1)
          this.setState({ cartFail: true });
        else
          this.setState({ cartLoaded: true });
      }
      catch (e) {
        console.error(e);
        this.setState({ cartFail: true });
      }
    };

    componentDidMount() {
      this._loadCart();
    }

    render() {
      const { cartFail, cartLoaded } = this.state;
      if (cartFail)
        return <Redirect to={'/store'}/>;
      if (cartLoaded)
        return <OriginalComponent {...this.props}/>;
      else
        return null;
    }
  }
};

export default withCartChecking;
