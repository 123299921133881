import React, {Component} from 'react';
import BackToStoreButton from "./BackToStoreButton";
import PropTypes from 'prop-types';
import Swipe from 'react-easy-swipe';

import { bounceOutLeft, bounceOutRight, bounceIn } from 'react-animations';
import Radium, { StyleRoot } from 'radium';

const styles = {
  bounceIn: {
    animation: 'x 1s',
    animationName: Radium.keyframes(bounceIn, 'bounceIn')
  },
  bounceOutLeft: {
    animation: 'x 1s',
    animationName: Radium.keyframes(bounceOutLeft, 'bounceOutLeft')
  },
  bounceOutRight: {
    animation: 'x 1s',
    animationName: Radium.keyframes(bounceOutRight, 'bounceOutRight')
  }
};

const AnimatedImg = props => (
  <StyleRoot>
    <img {...props}/>
  </StyleRoot>
);

class Slider extends Component {
  static defaultProps = {
    title: null,
  };

  state = {
    active: 0,
    animation: {},
    activeImage: null,
  };

  handleClick = key => {
    let animation = {};
    const { active } = this.state;
    if (key < active)
      animation = styles.bounceOutRight;
    else if (key > active)
      animation = styles.bounceOutLeft;
    this.setState({ active: key, animation });
  };

  componentDidMount() {
    const { images } = this.props;
    this.setState({
      activeImage: <AnimatedImg className="store__slide__img" src={images[0]} alt="" />
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { active, animation } = this.state;
    const { images } = this.props;
    if (active !== prevState.active) {
      this.setState({
        activeImage: <AnimatedImg className="store__slide__img" src={images[prevState.active]} alt="" style={animation}/>
      });
      setTimeout(() => {
        this.setState({
          activeImage: <AnimatedImg className="store__slide__img" src={images[active]} alt="" style={styles.bounceIn}/>
        });
      }, 300);
    }
  }

  handleSwipeRight = () => {
    const { active } = this.state;
    // const { images } = this.props;
    if (active > 0) {
      this.setState({
        active: active - 1,
        animation: styles.bounceOutRight,
      })
    }
  };

  handleSwipeLeft = () => {
    const { active } = this.state;
    const { images } = this.props;
    if (active < images.length - 1) {
      this.setState({
        active: active + 1,
        animation: styles.bounceOutLeft,
      })
    }
  };

  render() {
    const { images, title } = this.props;
    const { active, activeImage } = this.state;
    return (
      <div className="store__slider">
        <div className="store__title">
          <BackToStoreButton/>
          {title}
        </div>
        <div className="slider slider-for slick-initialized slick-slider">
          <div className="slick-list">
            <div className="slick-track">
              <Swipe
                onSwipeLeft={this.handleSwipeLeft}
                onSwipeRight={this.handleSwipeRight}
              >
                <div className="slick-slide slick-current slick-active">
                  {/*<a className="popup-link" href="../../images/bg/slider/phone-1.jpg">*/}
                  {/*    <img className="store__slider__popup-link__img" src={ZoomPic} alt=""/>*/}
                  {/*</a>*/}
                  {activeImage}
                </div>
              </Swipe>
            </div>
            <ul className="slick-dots">
              {images.map((_, key) => (
                <li className={key === active ? 'slick-active' : ''} key={key} onClick={() => this.handleClick(key)}>
                  <button type="button">{key + 1}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="slider slider-nav slick-initialized slick-slider">
          <div className="slick-track">
            {images.map((img, key) => (
              <div className={`slick-slide ${active === key && 'slick-active'}`} key={key} onClick={() => this.handleClick(key)}>
                <img className="slider-nav__img" src={img} alt=""/>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

Slider.propTypes = {
  images: PropTypes.array.isRequired,
  title: PropTypes.any
};

export default Slider;
