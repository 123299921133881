import React from 'react';

const LoaderCircle = ({ width = 60, height = 60 }) => {
  return (
    <span className="loader-circle" style={{ width, height }}>
            <span className="loader-circle1 loader-child"/>
            <span className="loader-circle2 loader-child"/>
            <span className="loader-circle3 loader-child"/>
            <span className="loader-circle4 loader-child"/>
            <span className="loader-circle5 loader-child"/>
            <span className="loader-circle6 loader-child"/>
            <span className="loader-circle7 loader-child"/>
            <span className="loader-circle8 loader-child"/>
            <span className="loader-circle9 loader-child"/>
        </span>
  );
};

export default LoaderCircle;
