import React, {Component} from 'react';
import { Link } from "react-router-dom";
import {CartPic, ClosePic, notifyPics} from "../../../../constants/images";
import {GSMBOX_DB_CODE, PHONE_DB_CODE} from "../../../../constants";
import PropTypes from 'prop-types';
import numberFormatter from "number-formatter";

class CartItemNotify extends Component {
  componentDidMount() {
    const { onClose } = this.props;
    setTimeout(onClose, 5000);
  }

  render() {
    const { onClose, count, item, cartToken } = this.props;
    return (
      <div className="nonmodal__item">
        <div className="nonmodal__title">
          Item has been added to cart
        </div>
        <div className="nonmodal__content">
          <div className="img__wrap">
            <img className="nonmodal__content__img" src={notifyPics[GSMBOX_DB_CODE]} alt=""/>
          </div>
          <div className="wrap">
            <p>{item.item_name}</p>
            <p className="price">₣{numberFormatter('#,##0.00', item.item_price)}</p>
            <span>Qty: {count}</span>
          </div>
        </div>
        <div className="nonmodal__footer">
          <div className="wrap">
            <span>Estimated Total</span>
            <p>₣{numberFormatter('#,##0.00', item.item_price * count)}</p>
          </div>

          <Link to={`/cart/${cartToken}`} className="btn add-to-cart">
            <img
              className="nonmodal__footer__add-to-cart__img"
              src={CartPic} alt=""
            />
            Go to cart
          </Link>

        </div>
        <span className="close" onClick={onClose}><img src={ClosePic} alt=""/></span>
      </div>
    );
  }
}

CartItemNotify.propTypes = {
  onClose: PropTypes.func.isRequired,
  uid: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  cartToken: PropTypes.string.isRequired,
};

export default CartItemNotify;
