import React, {Fragment} from 'react';
import {scrollToTop} from "../../lib/scrollToTop";

import SelectLanguage from "./SelectLanguage";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import {LogoSymbol, ToTop, ToTopActive} from "../../constants/images";
import PropTypes from 'prop-types';

const Footer = ({ store, withoutTop, store: { i18n } }) => {
  const { changeLanguage } = store;
  return (
    <Fragment>
      {withoutTop !== true &&
      <div className="footer-top">
        <img src={LogoSymbol} className="footer-top__img" alt={''}/>
        <h2>{i18n('footer', 'footer_servers')}</h2>
        <p>
          {i18n('footer', 'footer_our_servers')}
        </p>
      </div>
      }
      <footer className="footer">
        <div className="arrow__up active" onClick={() => scrollToTop(100)}>
          <img src={ToTop} className="arrow__up__img" alt={''}/>
          <img src={ToTopActive} className="arrow__up__img" alt={''}/>
        </div>
        <div className="footer-bottom">
          <div className="wrap-link">
            <p className="copy">© 2020 boqpod GmbH Zug, Switzerland</p>
            <div className="wrap">
              <Link to={'/terms'}>Terms of Use</Link>
              <Link to={'/privacy'}>Privacy Policy</Link>
            </div>
          </div>
          <SelectLanguage
            items={store.languages}
            currentLanguage={store.language}
            onSelect={changeLanguage}
          />
        </div>
      </footer>
    </Fragment>
  );
};

Footer.propTypes = {
  withoutTop: PropTypes.bool,
};

export default inject('store')(observer(Footer));
