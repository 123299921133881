import React, {Component} from 'react';

class BillingAddressForm extends Component {
  render() {
    return (
      <div className="billing__block">
        <div className="billing__title">
          <p>Billing address</p>
          <span className="span-edit">edit</span>
        </div>
        <div className="wrap">
          <p>Huỳnh Quỳnh Nhung</p>
          <p>Augustinergasse 9, 8001 Zürich, Switzerland</p>
        </div>
        <div className="billing__body">
          <div className="select__wrap error">
            <label>Full Name</label>
            <input type="text"/>
            <div className="error">
              Name is required
            </div>
          </div>
          <div className="select__wrap select__wrap--short not-mob success">
            <label>Country</label>
            <div className="select__wrapper select__wrapper--checkout">
              <div className="select__head active">
                Switzerland <img className="select__head__img"
                                 src="images/svg/arrow-down.svg"
                                 alt=""/>
              </div>
              <div className="select__list">
                <span>Switzerland</span>
                <span>Alabama</span>
                <span>Wyoming</span>
              </div>
            </div>
            <div className="error">
              Country is required
            </div>
          </div>
          <div className="select__wrap error">
            <label>Address</label>
            <input type="text"/>
            <div className="error">
              Address is required
            </div>
          </div>
          <div className="select__wrap select__wrap-2 not-mob success">
            <div className="wrapper">
              <div className="wrap">
                <label>Zip</label>
                <input type="text"/>
              </div>
              <div className="wrap state">
                <label>State <em>(Optional)</em></label>
                <input type="text"/>
              </div>
            </div>
            <div className="error">
              Zip/State is required
            </div>
          </div>
          <div className="select__wrap select__wrap--short error">
            <label>City</label>
            <input type="text"/>
            <div className="error">
              City is required
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BillingAddressForm;
