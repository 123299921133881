import React from 'react';
import { withRouter } from 'react-router-dom';
import {ArrowRightSmallPic} from "../../constants/images";

const BackToStoreButton = ({ history: { push }}) => (
  <span
    onClick={() => push('/store')}
  >
                        <img
                          className="store__title__img"
                          src={ArrowRightSmallPic}
                          alt=""
                        />
                        back to store
                    </span>
);

export default withRouter(BackToStoreButton);
