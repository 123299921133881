import React, {Component} from 'react';
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import Slider from "../../Slider";
import {GSMBOX_DB_CODE, PHONE_DB_CODE} from "../../../constants";
import {ArrowRightSmallPic, RecommendBoxPic} from "../../../constants/images";
import PlusMinus from "../../PlusMinus";
import OrderPopup from "../StorePopup/OrderPopup";
import AddToCartButton from "../AddToCartButton";
import DeliveryInfoBlock from "../DeliveryInfoBlock";
import PayWith from "../PayWith";
import ReturnPolicyPopup from "../StorePopup/ReturnPolicyPopup";
import numberFormatter from 'number-formatter';
import withHandleAddItem from "../../../decorators/withHandleAddItem";
import StockInfo from "../../StockInfo";

const phoneImages = [
  require('../../../images/bg/slider/phone-1.jpg'),
  require('../../../images/bg/slider/phone-2.jpg'),
  require('../../../images/bg/slider/phone-3.jpg'),
  require('../../../images/bg/slider/phone-4.jpg'),
  require('../../../images/bg/slider/phone-5.jpg'),
];

class PhoneStoreBlock extends Component {
  state = {
    phonesCount: 1,
    boxesCount: 1,
  };

  handleChangePhonesCount = phonesCount => this.setState({ phonesCount });
  handleChangeBoxesCount = boxesCount => this.setState({ boxesCount });

  handleAddPhone = () => {
    this.props.handleAddItem(PHONE_DB_CODE, this.state.phonesCount);
    this.setState({ phonesCount: 1 });
  };

  handleAddGsmBox = () => {
    this.props.handleAddItem(GSMBOX_DB_CODE, this.state.boxesCount);
    this.setState({ boxesCount: 1 });
  };

  render() {
    const {
      store,
      store: {
        storeItems,
        cartAddButtons,
        i18n,
      }
    } = this.props;

    return (
      <div className="store">
        <div className="store__wrapper">
          <Slider
            images={phoneImages}
            title={(
              <>
                <h2>
                  {storeItems[PHONE_DB_CODE].item_name}
                </h2>
                <p>{storeItems[PHONE_DB_CODE].item_description}</p>
              </>
            )}
          />
          <div className="store__add-cart">
            <div className="store__title">
              <h2>
                {storeItems[PHONE_DB_CODE].item_name}
              </h2>
              <p>{storeItems[PHONE_DB_CODE].item_description}</p>
            </div>
            <div className="store__add-cart__wrap">
              <div className="store__price">
                ₣{numberFormatter('#,##0.00', storeItems[PHONE_DB_CODE].item_price)}
              </div>
              <StockInfo available={storeItems[PHONE_DB_CODE].item_available}/>
              <div className="store__amount__wrap">
                <PlusMinus
                  value={this.state.phonesCount}
                  onChange={this.handleChangePhonesCount}
                  min={1}
                />
                {/*<p>*/}
                {/*  A minimum order of 2 phones is required for delivery to Europe <span*/}
                {/*  className="open-popup-link"*/}
                {/*  onClick={() => store.storePopup = <OrderPopup/>}*/}
                {/*>Learn more <img*/}
                {/*    src={ArrowRightSmallPic}*/}
                {/*    alt=""/></span>*/}
                {/*</p>*/}
              </div>
              <AddToCartButton
                loading={cartAddButtons[PHONE_DB_CODE]}
                onClick={this.handleAddPhone}
                title={'Add to cart'}
              />
              <DeliveryInfoBlock/>
              <PayWith/>
              <span
                className="open-popup-link"
                onClick={() => store.storePopup = <ReturnPolicyPopup/>}
              >Return policy<img
                src={ArrowRightSmallPic}
                alt=""/></span>

            </div>
            <div className="store__recommend-add">
              <div className="store__recommend-add__title">
                We recommend to add
              </div>
              <div className="wrapper">
                <div className="wrap">
                  <img className="store__recommend-add__img" src={RecommendBoxPic} alt=""/>
                  <div className="title-wrap">
                    <p><Link style={{
                      color: 'white',
                      textDecoration: 'none',
                    }} to={'/store/gsmbox'}>{storeItems[GSMBOX_DB_CODE].item_name}</Link></p>
                    <span>₣{numberFormatter('#,##0.00', storeItems[GSMBOX_DB_CODE].item_price)}</span>
                  </div>
                </div>
                <div className="wrap-2">
                  <div className="title-wrap">
                    <p>{storeItems[GSMBOX_DB_CODE].item_name}</p>
                    <span>₣ {storeItems[GSMBOX_DB_CODE].item_price}</span>
                  </div>
                  <PlusMinus
                    value={this.state.boxesCount}
                    onChange={this.handleChangeBoxesCount}
                    min={1}
                  />
                  <AddToCartButton
                    loading={cartAddButtons[GSMBOX_DB_CODE]}
                    onClick={this.handleAddGsmBox}
                    title={'Add to cart'}
                    small
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withHandleAddItem(inject('store')(observer(PhoneStoreBlock)));
