import React, {Component} from 'react';
import { inject, observer } from "mobx-react";
import CartItemNotify from "./StorePopup/CartItemNotify";

class MapNotifications extends Component {
  render() {
    const { store } = this.props;
    if (store.notifyPopups.length === 0) return null;
    return (
      <div className="nonmodal__block">
        {store.notifyPopups.map((popup, key) => {
          return (
            <CartItemNotify
              key={key}
              onClose={() => {
                store.notifyPopups = store.notifyPopups.filter(p => p.uid !== popup.uid)
              }}
              cartToken={store.cart.cart_token}
              {...popup}
            />
          )
        })}
      </div>
    );
  }
}

export default inject('store')(observer(MapNotifications));

