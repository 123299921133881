import React, {Component, Fragment} from 'react';
import { inject, observer } from "mobx-react";
import numberFormatter from 'number-formatter';
import { cryptoPics } from '../../../constants/images';

class PayByCryptoTab extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currencyCode: props.store.cryptos[0].currency_code,
    }
  }

  handleChange = ({ target: { value } }) => this.props.store.checkout.payment_type = value;

  render() {
    const { store } = this.props;
    const { checkout } = store;
    return (
      <Fragment>
        <ul>
          {store.cryptos.map((item, key) => (
            <li key={key}>
              <label className="radio">
                <input
                  type="radio"
                  name="crypto_currency"
                  value={item.currency_code}
                  checked={checkout.payment_type === item.currency_code}
                  onChange={this.handleChange}
                />
                <div className="radio__text">
                  <div className="radio__text__wrap">
                    <div className="img-wrap"><img src={cryptoPics[item.currency_code]} alt=""/></div>
                    <p>{item.currency_name}</p>
                  </div>
                  {/*<p className="price">{numberFormatter('#,##0.00', store.estimatedTotal / item.chf_rate)} {item.currency_short_name}</p>*/}
                  <p className="price">{Math.round((store.estimatedTotal / item.chf_rate) * 100) / 100} {item.currency_short_name}</p>

                </div>
              </label>

            </li>
          ))}
        </ul>
      </Fragment>
    );
  }
}

export default inject('store')(observer(PayByCryptoTab));
