import axios from 'axios';
import {API_ENDPOINT} from "../constants";

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common.Accept = 'application/json';

const fetch_languages = () => axios({ method: 'get', url: `${API_ENDPOINT}/api/languages` });
const fetch_deliveries = () => axios({ method: 'get', url: `${API_ENDPOINT}/api/deliveries` });
const fetch_countries = () => axios({ method: 'get', url: `${API_ENDPOINT}/api/countries` });
const fetch_cryptos = () => axios({ method: 'get', url: `${API_ENDPOINT}/api/crypto_currencies` });
const fetch_items = () => axios({ method: 'get', url: `${API_ENDPOINT}/api/items` });
const create_cart = () => axios({ method: 'get', url: `${API_ENDPOINT}/api/cart/create` });
const fetch_cart = cart_token => axios({ method: 'get', url: `${API_ENDPOINT}/api/cart/${cart_token}/get` });
const delete_cart = cart_token => axios({ method: 'delete', url: `${API_ENDPOINT}/api/cart/${cart_token}/delete` });
const set_cart_items = (cart_token, data) => axios({ method: 'post', url: `${API_ENDPOINT}/api/cart/${cart_token}/set`, data });
const remove_cart_item = (cart_token, item_code) => axios({ method: 'get', url: `${API_ENDPOINT}/api/cart/${cart_token}/${item_code}/remove` });
const fetch_bitcoin_rates = () => axios({ method: 'get', url: `https://blockchain.info/en/ticker` });
const create_order = (cart_token, data) => axios({ method: 'post', url: `${API_ENDPOINT}/api/order/create/${cart_token}`, data });
const get_bitcoin_qr_code = (wallet, amount, qr_pic_size, message) => axios({ method: 'get', url: `https://chart.googleapis.com/chart?chs=${qr_pic_size}x${qr_pic_size}&cht=qr%26chl=bitcoin:1${wallet}?amount=${amount}%26message=${message}` });
const fetch_order = order_number => axios({ method: 'get', url: `${API_ENDPOINT}/api/order/${order_number}` });
const cancel_order = order_number => axios({ method: 'delete', url: `${API_ENDPOINT}/api/order/${order_number}/cancel` });
const get_client_ip = () => axios({ method: 'get', url: `${API_ENDPOINT}/api/client_ip` });
const get_sha_key = () => axios({ method: 'get', url: `${API_ENDPOINT}/api/sha_key` });
const get_country_code = () => axios({ method: 'get', url: `${API_ENDPOINT}/api/client_country` });
const get_country_by_ip = ip => axios({ method: 'get', url: `https://pro.ip-api.com/json/${ip}?key=0yTjkfEK1CC8rPi` });
const fetch_content = lang => axios({ method: 'get', url: `${API_ENDPOINT}/api/content/${lang}` });

export {
  fetch_languages,
  fetch_deliveries,
  fetch_countries,
  create_cart,
  fetch_items,
  set_cart_items,
  fetch_cart,
  remove_cart_item,
  fetch_bitcoin_rates,
  fetch_cryptos,
  get_bitcoin_qr_code,
  create_order,
  fetch_order,
  cancel_order,
  delete_cart,
  get_client_ip,
  get_country_by_ip,
  get_sha_key,
  fetch_content,
  get_country_code,
};
