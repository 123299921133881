import React, {Component} from 'react';
import { Link, withRouter, NavLink } from "react-router-dom";
import { inject, observer } from "mobx-react";
import {menuRoutes} from "../../routes";
import withSticky from "../../decorators/withSticky";

class NavLine extends Component {
  render() {
    const { mobileMenuClass } = this.props.store;
    return (
      <nav className={`nav ${mobileMenuClass}`} style={{ opacity: this.props.sticky ? 0 : 1 }}>
        {menuRoutes.map((route, key) => {
          return (
            <NavLink to={route.path} exact={route.exact} key={key} activeClassName={'active'}>{route.name}</NavLink>
          );
        })}

        <div className="nav__block">
          {/*<Link to={'/blog'}>Blog</Link>*/}
          <Link to={'/terms'}>Terms of use</Link>
          <Link to={'/privacy'}>Privacy Policy</Link>
        </div>
      </nav>
    );
  }
}

export default withSticky(withRouter(inject('store')(observer(NavLine))));
