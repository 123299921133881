import React from 'react';
import { inject, observer } from 'mobx-react';
import PageContainer from "../components/PageContainer";
import Header from "../components/Header";
import NavLine from "../components/Header/NavLine";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import {GsmBoxImage2} from "../constants/images";

const GsmBox = ({ store: { i18n }}) => (
  <PageContainer>
    <Header/>
    <div className="container">
      <div className="wrap-bg gsm-box">

        <NavLine/>

        <div className="description-block description-block--1 description-block--btn">
          <div className="wrap">
            <span>{i18n('gsmbox', 'gsmbox')}</span>
            <h2><div dangerouslySetInnerHTML={{ __html: i18n('gsmbox', 'gsmbox_forwarding_incoming') }} /></h2>
            <p>{i18n('gsmbox', 'gsmbox_the_device')}</p>
            <Link to={'/store/gsmbox'} className="btn">{i18n('gsmbox', 'gsmbox_buy_gsmbox')}</Link>
          </div>
        </div>
        <div className="text-block">
          <h2>{i18n('gsmbox', 'gsmbox_access_to_your')}</h2>
          <p>{i18n('gsmbox', 'gsmbox_calls_to')}</p>
        </div>
        <div className="text-block">
          <h2>{i18n('gsmbox', 'gsmbox_display')}</h2>
          <p>{i18n('gsmbox', 'gsmbox_the_small_display')}</p>
        </div>
        <div className="img-wrap gsm-box"><img className="gsm-box__img"
                                               src={GsmBoxImage2} alt=""/></div>
        <div className="text-block">
          <h2>{i18n('gsmbox', 'gsmbox_unlimited_gsm')}</h2>
          <p>{i18n('gsmbox', 'gsmbox_forget_about')}</p>
        </div>
        <div className="text-block text-block--h2">
          <h2>{i18n('gsmbox', 'gsmbox_connects_to_the')}</h2>

        </div>
        <div className="text-block">
          <h2>{i18n('gsmbox', 'gsmbox_keys_are')}</h2>
          <p>{i18n('gsmbox', 'gsmbox_the_keys_are')}</p>
        </div>
        <div className="text-block">
          <h2>{i18n('gsmbox', 'gsmbox_true_random')}</h2>
          <p>{i18n('gsmbox', 'gsmbox_we_do_not')}</p>
        </div>
      </div>
    </div>
    <Footer/>
  </PageContainer>
);

export default inject('store')(observer(GsmBox));
