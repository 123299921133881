import React, {Component} from 'react';
import {LockPic, PhoneImage3} from "../../constants/images";
import { inject, observer } from "mobx-react";
import EstimatedTotal from "./EstimatedTotal";
import StockInfo from "../StockInfo";
import numberFormatter from 'number-formatter';
import PlusMinus from "../PlusMinus";
import {addItemToCart, removeItemFromCart} from "../../lib/cart";
import {Link, Redirect} from 'react-router-dom';
import LoaderCircle from "../PageContainer/LoaderCircle";

class ShoppingCart extends Component {

  state = {
    fetching: '',
  };

  handleRemove = async itemCode => {
    await removeItemFromCart(itemCode);
  };

  changeCount = async (item_code, value) => {
    this.setState({ fetching: item_code });
    await addItemToCart(item_code, value);
    setTimeout(() => this.setState({ fetching: '' }), 400);
  };

  mapItems = () => {
    const { fetching } = this.state;
    const { store: { cart, storeItems } } = this.props;
    return cart.items.map((item, key) => {
      return (
        <div className="store--cart__item" key={key}>
          <div className="wrap">
            <div className="store--cart__img">
              <img className="store--cart__img__img" src={PhoneImage3}
                   alt=""/>
            </div>
            <div className="store__add-cart">
              <div className="store__title">
                <h2>
                  {storeItems[item.item_code].item_name}
                </h2>
                {storeItems[item.item_code].item_description.length > 1 &&
                <p>{storeItems[item.item_code].item_description}</p>
                }
              </div>
              <div className="store__add-cart__wrap">
                <StockInfo available={storeItems[item.item_code].item_available}/>
                <div className="store__amount__wrap">
                  {fetching !== '' && fetching === item.item_code
                    ?
                    <div style={{ width: 135, height: 82, textAlign: 'center' }}>
                      <LoaderCircle
                        width={50}
                        height={50}
                      />
                    </div>
                    :
                    <PlusMinus
                      value={item.count}
                      onChange={value => this.changeCount(item.item_code, value > item.count ? 1 : -1)}
                      min={1}
                    />
                  }
                </div>
                <span className="del" onClick={() => this.handleRemove(item.item_code)}>Remove</span>
              </div>
            </div>
          </div>
          <div className="store__price__wrap">
            <div className="store__price">
              ₣{numberFormatter('#,##0.00', storeItems[item.item_code].item_price * item.count)}
            </div>
            <p>Price per item – ₣{numberFormatter('#,##0.00', storeItems[item.item_code].item_price)}</p>
            <span className="del">Remove</span>
          </div>
        </div>
      );
    });
  };

  render() {
    const { cart } = this.props.store;
    if (cart.items.length < 1) return <Redirect to={'/store'}/>;
    return (
      <div className="store__wrapper">
        <div className="store--cart__item__wrap">
          {this.mapItems()}
        </div>
        <EstimatedTotal
          renderButton={() => (
            <Link to={`/checkout/${cart.cart_token}`} className="btn button">
              <img
                className="total-block__total-cost__img"
                src={LockPic}
                alt=""/>
              Secure checkout
            </Link>
          )}
        />
      </div>
    );
  }
}

export default inject('store')(observer(ShoppingCart));
