import React, {useState} from 'react';
import {langIcons} from "../../constants/images";
import PropTypes from 'prop-types';

const SelectLanguage = props => {
  const { items, currentLanguage, onSelect } = props;
  const [isOpen, setOpen] = useState(false);
  const currentLang = items.find(item => item.lang_key === currentLanguage);
  return (
    <div
      className={`footer__lang ${isOpen && 'active'}`}
      onClick={() => setOpen(!isOpen)}
    >
      <ul className="footer__lang__dropdown">
        {items.filter(item => item.lang_key !== currentLanguage).map((item, key) => {
          return (
            <li key={key} onClick={() => onSelect(item.lang_key)}>{item.lang_name}</li>
          );
        })}
      </ul>
      <div className="footer__lang__head">
        <img src={langIcons[currentLang.lang_key]} className="footer__lang__head__img" alt={''}/>{currentLang.lang_name}
      </div>
    </div>
  );
};

SelectLanguage.propTypes = {
  items: PropTypes.array.isRequired,
  currentLanguage: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default SelectLanguage;
