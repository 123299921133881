import React, {Component} from 'react';
import { inject, observer } from "mobx-react";
import numberFormatter from 'number-formatter';
import {ArrowRightSmallPic, QuestionActivePic, QuestionPic} from "../../../constants/images";
import ReturnPolicyPopup from "../../Store/StorePopup/ReturnPolicyPopup";
import ShippingCostPopup from "../../Store/StorePopup/ShippingCostPopup";
import PropTypes from 'prop-types';

class EstimatedTotal extends Component {

  state = {
    yOffset: 0,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const yOffset = window.pageYOffset || document.documentElement.scrollTop;
    this.setState({ yOffset });
  };

  render() {
    const {
      store,
      store: { estimatedTotal, cart, cartTotal },
      wrapperClasses,
      hidePolicy,
      renderButton,
      disablePopups,
    } = this.props;

    const delivery = store.deliveries.find(item => item.id === store.cart.delivery_id);
    const selectedCountry = store.countries.find(item => item.country_code === store.cart.country_code);

    return (
      <div className={`total-block__wrap ${wrapperClasses}`} style={{ paddingTop: this.state.yOffset }}>
        <div className="total-block">
          <div className="total-block__title">Estimated Total</div>
          <ul>
            <li>
              <p>Subtotal ({cart.items.length} items)</p>
              <p className="price">₣{numberFormatter('#,##0.00', cartTotal)}</p>
            </li>
            <li>
              {disablePopups !== true
                ?
                <p>
                  Estimated&nbsp;Shipping&nbsp;to {selectedCountry.country_name}
                </p>
                :
                <p>
                  Estimated&nbsp;Shipping to&nbsp;{selectedCountry.country_name}
                </p>
              }
              <p className="price">
                {delivery !== undefined
                  ?
                  `₣${delivery.delivery_price}`
                  :
                  `-`
                }
              </p>
            </li>
            {/*<li>*/}
            {/*  <p>*/}
            {/*    Taxes*/}
            {/*    <span className="question-popover-wrap">*/}
            {/*      <img*/}
            {/*      className="question-popover-wrap__img"*/}
            {/*      src={QuestionPic}*/}
            {/*      alt=""*/}
            {/*      />*/}
            {/*                    */}
            {/*      <img className="question-popover-wrap__img" src={QuestionActivePic} alt=""/>*/}
            {/*      <span className="question-popover">*/}
            {/*        <em>Courier will fly to any country for delivery. Minimum order – 5 items.</em>*/}
            {/*      </span>*/}
            {/*    </span>*/}
            {/*  </p>*/}
            {/*  <p className="price">Not calculated</p>*/}
            {/*</li>*/}
          </ul>
          <div className="total-block__total-cost">
            <p>Estimated Total</p>
            <p className="price">₣{numberFormatter('#,##0.00', estimatedTotal)}</p>
          </div>
          {renderButton !== undefined && renderButton()}
        </div>

        {!hidePolicy &&
        <span
          className="open-popup-link"
          onClick={() => store.storePopup = <ReturnPolicyPopup/>}
        >Return policy<img
          src={ArrowRightSmallPic} alt=""/>
                </span>
        }
      </div>
    );
  }
}

EstimatedTotal.propTypes = {
  wrapperClasses: PropTypes.string,
  hidePolicy: PropTypes.bool,
  renderButton: PropTypes.func,
  disablePopups: PropTypes.bool,
};

export default inject('store')(observer(EstimatedTotal));
