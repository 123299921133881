import React, {Component, Fragment} from 'react';
import Chat from '../../images/svg/chat.svg';
import ChatActive from '../../images/svg/chat-active.svg';
import Group from '../../images/svg/group.svg';
import GroupActive from '../../images/svg/group-active.svg';
import Calls from '../../images/svg/calls.svg';
import CallsActive from '../../images/svg/calls-active.svg';
import { inject, observer } from 'mobx-react';

class MessengerDescription extends Component {
  state = {
    active: 0,
  };

  render() {
    const { store: { i18n } } = this.props;
    const items = [
      {
        dataImg: 'img1',
        content:
          <Fragment>
            <img src={Chat} className="messenger__img" alt={''}/>
            <img src={ChatActive} className="messenger__img" alt={''}/>
            <p>{i18n('messenger', 'messenger_private_messages')}</p>
          </Fragment>
      },
      {
        dataImg: 'img2',
        content:
          <Fragment>
            <img className="messenger__img" src={Group} alt=""/>
            <img className="messenger__img" src={GroupActive} alt=""/>
            <p>{i18n('messenger', 'messenger_group_chats')}</p>
          </Fragment>
      },
      {
        dataImg: 'img3',
        content:
          <Fragment>
            <img className="messenger__img" src={Calls} alt=""/>
            <img className="messenger__img" src={CallsActive} alt=""/>
            <p>{i18n('messenger', 'messenger_audio_and_video_calls_hd')}</p>
          </Fragment>
      },
    ];

    return (
      <div className={`description-block description-block--messenger description-block--messenger-2 ${items[this.state.active].dataImg}`}>
        <div className="wrap">
          <span>{i18n('messenger', 'messenger_messenger')}</span>
          <h2>{i18n('messenger', 'messenger_everything_you_need')}</h2>
          <p>{i18n('messenger', 'messenger_send_messages')}
          </p>
          <ul>
            {items.map((item, key) => {
              return (
                <li
                  className={this.state.active === key ? 'active' : ''}
                  key={key}
                  onMouseOver={() => this.setState({ active: key })}
                >
                  {item.content}
                </li>
              );
            })}
          </ul>
          <a href="#features" className="btn">{i18n('messenger', 'messenger_read_more')}</a>
        </div>
      </div>
    );
  }
}

export default inject('store')(observer(MessengerDescription));
