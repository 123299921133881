import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import {CONTACT_TAB_EMAIL} from "../../constants";
import OrderShortInfo from "./OrderInfo/OrderShortInfo";

const OrderConfirmed = ({ order }) => {
  const {
    order_number,
    user,
  } = order;
  return (
    <Fragment>
      <h2>Thank you for your order!</h2>
      {/*{user.contact_by === CONTACT_TAB_EMAIL &&*/}
      {/*<p>*/}
      {/*  A copy of this order confirmation has been sent to your email address,&nbsp;*/}
      {/*  <a href={`mailto:${user.email}`}>{user.email}</a>*/}
      {/*</p>*/}
      {/*}*/}
      <p>Please note the following information:</p>
      <OrderShortInfo order={order}/>
      <p>You can track your order status using <Link to={`/order/${order_number}`}>Track order</Link> page</p>
    </Fragment>
  );
};

OrderConfirmed.propTypes = {
  order: PropTypes.object.isRequired,
};

export default OrderConfirmed;
