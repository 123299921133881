import React, {Component} from 'react';
import { inject, observer } from "mobx-react";
import PageContainer from "../components/PageContainer";
import Footer from "../components/Footer";
import CartBlock from "../components/Header/CartBlock";
import {LogoNoTagLinePic, LogoPic} from "../constants/images";
import withCartChecking from "../decorators/withCartChecking";
import SecureCheckout from "../components/SecureCheckout";

class Checkout extends Component {

  render() {
    const {
      store: {
        storePopup,
        i18n,
      }
    } = this.props;
    return (
      <PageContainer>
        <div className="checkout">
          <header className="header checkout">
            <div className="header__wrap">
              <div className="header__m-menu">
              </div>
              <div className="header__logo__sticky">
                <a href=""><img className="header__logo__sticky__img" src={LogoNoTagLinePic}
                                alt=""/></a>
                <a href=""><img className="header__logo__sticky__img" src={LogoPic} alt=""/></a>
              </div>
              <div className="header__title">
                {i18n('checkout', 'checkout_checkout')}
              </div>
              <CartBlock/>
            </div>
          </header>
          <SecureCheckout/>
          <Footer withoutTop={true}/>
          {storePopup}
        </div>
      </PageContainer>
    );
  }
}

export default withCartChecking(inject('store')(observer(Checkout)));
