import React from 'react';
import {payWith} from "../../constants/images";

const PayWith = () => {
  return (
    <ul className="store__pay-with">
      <li>Pay with</li>
      {payWith.map((img, key) => <li key={key}><img src={img} alt={''}/></li>)}
    </ul>
  );
};

export default PayWith;
