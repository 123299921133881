import React, {Component} from 'react';
import { inject, observer } from "mobx-react";
import PageContainer from "../components/PageContainer";
import Header from "../components/Header";
import NavLine from "../components/Header/NavLine";
import Footer from "../components/Footer";
import ShoppingCart from "../components/ShoppingCart";
import withCartChecking from "../decorators/withCartChecking";

class Cart extends Component {
  render() {
    const { store } = this.props;
    return (
      <PageContainer>
        <Header/>
        <div className="container">
          <div className="wrap-bg">

            <NavLine/>

            <div className="store store--cart">
              <div className="store__title">
                <h2>
                  Shopping Cart
                </h2>
              </div>
              <ShoppingCart/>
            </div>

          </div>
        </div>
        <Footer withoutTop={true}/>
        {store.storePopup}
      </PageContainer>
    );
  }
}

export default withCartChecking(inject('store')(observer(Cart)));
