import React, {Component} from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import NotFound from "./pages/NotFound";
import {cartRoutes, menuRoutes, orderRoutes, siteRoutes} from "./routes";
import RouterScrollToTop from "./components/RouterScrollToTop";

import { inject, observer } from "mobx-react";

import './styles/style.css';
import './styles/fixes.css';
import Loader from "./components/PageContainer/Loader";
import GlobalError from "./components/GlobalError";


class App extends Component {
  state = {
    ready: false
  };

  async componentDidMount() {
    const { initApp } = this.props.store;
    await initApp();
    this.setState({ ready: true });
  }

  componentWillUnmount() {
    const { store } = this.props;
    clearInterval(store.btcInterval);
  }

  render() {
    const { ready } = this.state;
    const { apiFail } = this.props.store;
    if (!ready) return <Loader/>;
    if (apiFail) return <GlobalError/>;
    return (
      <BrowserRouter>
        <RouterScrollToTop>
          <Switch>
            {[...siteRoutes, ...menuRoutes, ...cartRoutes, ...orderRoutes].map((route, key) => {
              return <Route exact={route.exact === true} path={route.path} component={route.component} key={key}/>
            })}
            <Route component={NotFound}/>
          </Switch>
        </RouterScrollToTop>
      </BrowserRouter>
    );
  }
}

export default inject('store')(observer(App));
