import React, {Component} from 'react';
import CloseButton from "../CloseButton";
import {QuestionActivePic, QuestionPic} from "../../../../constants/images";
import CustomSelector from "./CustomSelector";
import { inject, observer } from "mobx-react";

class ShippingCostPopup extends Component {
  handleChangeDelivery = ev => {
    const { store } = this.props;
    store.cart.delivery_id = parseInt(ev.target.value);
  };

  render() {
    const { store } = this.props;

    return (
      <div className="popup__wrapper mfp-close-btn-in">
        <div id="shipping-cost-popup" className="popup">
          <div className="popup__title">
            Shipping Cost
          </div>
          <p>Choose country to calculate estimated shipping cost</p>
          <CustomSelector
            items={store.countries.map(country => ({
              key: country.country_code,
              title: country.country_name,
            }))}
            keyActive={store.cart.country_code}
            onSelect={item => store.cart.country_code = item.key}
          />
          <ul>
            {
              store.deliveries.map((item, key) => {
                return (
                  <li key={key}>
                    <label className="radio">
                      <input
                        type="radio"
                        name="delivery_id"
                        value={item.id}
                        onChange={this.handleChangeDelivery}
                        checked={store.cart.delivery_id === item.id}
                      />
                      <div className="radio__text">
                        <p>{item.delivery_name} – ₣{item.delivery_price}</p>
                        <span>({item.delivery_description})</span>
                      </div>
                      <div className="question-popover-wrap">
                        <img
                          className="question-popover-wrap__img"
                          src={QuestionPic}
                          alt=""/>
                        <img className="question-popover-wrap__img"
                             src={QuestionActivePic} alt=""/>
                        <span className="question-popover">
                                            <em>{item.delivery_comment}</em>
                                        </span>
                      </div>
                    </label>
                  </li>
                );
              })
            }
          </ul>
          <CloseButton/>
        </div>

      </div>
    );
  }
}

export default inject('store')(observer(ShippingCostPopup));
