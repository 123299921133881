import React from 'react';
import PageContainer from "../components/PageContainer";
import Header from "../components/Header";
import NavLine from "../components/Header/NavLine";
import Footer from "../components/Footer";
import { ReactComponent as Space } from '../images/svg/space.svg';
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <PageContainer>
      <Header/>
      <div className="container">
        <div className="wrap-bg">
          <NavLine/>
        </div>
        <div className="error__block">
          <Space/>
          <h2>Oh no! This page is lost<br/> somewhere in space.</h2>
          <p>Error 404. Page not found.</p>
          <Link to={'/'} className="btn">Go to homepage</Link>
          <div className="error__nav">
            <Link to={'/'}>Phone</Link>
            <Link to={'/gsmbox'}>GSM box</Link>
            <Link to={'/messenger'}>Messenger</Link>
            <Link to={'/subscription'}>Subscription</Link>
            <Link to={'/store'}>Store</Link>
          </div>
        </div>
      </div>
      <Footer/>
    </PageContainer>
  );
};

export default NotFound;
