import React from 'react';
import PropTypes from 'prop-types';

const PlusMinus = props => {
  const { value, onChange, min, max } = props;

  const handlePlus = () => {
    let newValue = value + 1;
    if (min !== undefined && newValue > max)
      newValue = max;
    onChange(newValue);
  };

  const handleMinus = () => {
    let newValue = value - 1;
    if (min !== undefined && newValue < min)
      newValue = min;
    else
      onChange(newValue);
  };

  return (
    <div className="store__amount">
      <span className="store__amount-btn minus" onClick={handleMinus}>-</span> <em
      className="count">{value}</em><span className="store__amount-btn plus" onClick={handlePlus}>+</span>
    </div>
  );
};

PlusMinus.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
};

export default PlusMinus;
