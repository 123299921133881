import React, {Component} from 'react';
import { inject, observer } from "mobx-react/index";

class CloseButton extends Component {

  closePopup = () => {
    const { store } = this.props;
    store.storePopup = null;
  };

  handleKeyDown = ev => {
    if (ev.key === 'Escape')
      this.closePopup();
  };

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  render() {
    return (
      <button
        title="Close (Esc)"
        type="button"
        className="mfp-close"
        onClick={this.closePopup}
      >×</button>
    );
  }
};

export default inject('store')(observer(CloseButton));
