import React, {Component} from 'react';
import PageContainer from "../components/PageContainer";
import Header from "../components/Header";
import NavLine from "../components/Header/NavLine";
import Footer from "../components/Footer";
import CryptoPayment from "../components/Order/CryptoPayment";
import { inject, observer } from "mobx-react";

class Payment extends Component {
  componentDidMount() {
    const { clearCart } = this.props.store;
    clearCart();
  }

  render() {
    return (
      <PageContainer>
        <Header/>
        <div className="container">
          <div className="wrap-bg">
            <NavLine/>
          </div>
          <CryptoPayment/>
        </div>
        <Footer withoutTop={true}/>
      </PageContainer>
    );
  }
}

export default inject('store')(observer(Payment));
